import { useState } from 'react';
import farms from 'modules/farms';
import './styles/Sidebar.css';
import felcraLogo from 'assets/img/felcra-berhad-logo.png';
import { ReactComponent as OpenInNewIcon } from 'assets/img/open_in_new.svg';
import { ReactComponent as TriangleIcon } from 'assets/img/triangle.svg';
import { ReactComponent as MenuIcon } from 'assets/img/menu.svg';
import { ReactComponent as CloseIcon } from 'assets/img/close.svg';

const EXTERNAL_LINKS = {
  main: [
    { name: 'iFarm', url: 'https://felcra.azurewebsites.net' }
  ],
  cctvs: farms.selectedFarmData.cctvs
};

function Sidebar({
  OneDevice,
  selectedDataType,
  selectedView,
  weatherStations,
  soilDataLoggers,
  selectedDataSource,
  onDataTypeViewClick,
  onViewClick,
  onDataSourceClick,
  user

}) {
  const [isMobileExpanded, setIsMobileExpanded] = useState(false);
  console.log(soilDataLoggers);

  function handleDataTypeViewClick(view) {
    onDataTypeViewClick(view);
    setIsMobileExpanded(false);
  }

  function handleViewClick(view) {
    onViewClick(view);
    setIsMobileExpanded(false);
  }

  function handleDataSourceClick(dataSouce) {
    onDataSourceClick(dataSouce);
    setIsMobileExpanded(false);
  }

  function handleMobileExpandClick() {
    setIsMobileExpanded(prev => !prev);
  }



  
  return (
    <div className={'Sidebar' + (isMobileExpanded ? ' expanded' : '')}>
      <div className="desktop">
        <img id="felcra-berhad-logo" src={felcraLogo} alt="felcra logo" />

        {OneDevice===false? (
        <DataSourceType selectedView={selectedDataType} onClick={handleDataTypeViewClick} user={user}/>
        
        ):(<></>)}
        {user === "admin" || user === "felcrasgmelikai" ? (
          <ExternalLinks />
        ):(<></>)}
        <div className="divider"></div>


        <Views selectedView={selectedView} onClick={handleViewClick} user={user}/>



        
        <div className="divider"></div>
        
          {selectedDataSource.type === 'weather-station' ? (
              <WeatherStations
              els={weatherStations}
              selected={
                selectedDataSource.type !== 'weather-station'
                  ? null
                  : selectedDataSource
              }
              onClick={handleDataSourceClick}
            />
          ) : selectedDataSource.type === 'soil-data-logger' ? (
            <SoilDataLoggers
            els={soilDataLoggers}
            selected={
              selectedDataSource.type !== 'soil-data-logger'
                ? null
                : selectedDataSource
            }
            onClick={handleDataSourceClick}
          />

          ):(<></>)}
        


      </div>
      <MobileBottomBar
        view={selectedView}
        dataSource={selectedDataSource}
        isExpanded={isMobileExpanded}
        onClick={handleMobileExpandClick}
      />
    </div>
  );
}

function Views({ selectedView, onClick ,user}) {
  var views = ['Home', 'Devices','Chart','ANOVA','Regression'];
  if (user === "felcrasgmelikai" ){
    views = ['Home'];

  }




  function View({ label, isSelected, onClick }) {
    return (
      <button
        className={'View list-element' + (isSelected ? ' selected' : '')}
        onClick={() => onClick(label)}
      >
        {label}
      </button>
    );
  }

  return (
    <div className="Views list-container">
      {views.map(el => (
        <View
          key={el}
          label={el}
          isSelected={selectedView === el}
          onClick={onClick}
        />
      ))}
    </div>
  );
}


function DataSourceType({ selectedView, onClick ,user}) {
  var views = ['Weather Stations', 'Soil Sensors'];
  console.log(selectedView.toLowerCase().split(" ")[0]);
  function DataSourceTypeView({ label, isSelected, onClick }) {
    return (
      <button
        className={'DataSourceTypeView list-element' + (isSelected ? ' selected' : '')}
        onClick={() => onClick(label)}
      >
        {label}
      </button>
    );
  }

  return (
    <div className="DataSourceTypeViews list-container">
      {views.map(el => (
        <DataSourceTypeView
          key={el}
          label={el}
          isSelected={selectedView.toLowerCase().split("-")[0] === el.toLowerCase().split(" ")[0] }
          onClick={onClick}
        />
      ))}
    </div>
  );
}


function ExternalLinks() {
  function ExternalLink({ label, href }) {
    return (
      <a
        className="ExternalLink list-element"
        href={href}
        target="_blank"
        rel="noreferrer"
      >
        {label} <OpenInNewIcon />
      </a>
    );
  }

  function CctvsDropdown() {
    const [isExpanded, setIsExpanded] = useState(false);

    return (
      <div
        className={
          'CctvsDropdown list-container' + (isExpanded ? ' expanded' : '')
        }
      >
        <button
          className="title list-element"
          onClick={() => setIsExpanded(prev => !prev)}
        >
          CCTVs
          <TriangleIcon />
        </button>
        <div
          className="body list-container"
          style={{ '--num-cctvs': EXTERNAL_LINKS.cctvs.length }}
        >
          {getExternalLinks(EXTERNAL_LINKS.cctvs)}
        </div>
      </div>
    );
  }

  function getExternalLinks(links) {
    return links.map(el => (
      <ExternalLink key={el.name} label={el.name} href={el.url} />
    ));
  }

  return (
    <div className="ExternalLinks list-container">
      {getExternalLinks(EXTERNAL_LINKS.main)}

    </div>
  );
}

function WeatherStations({ els, selected, onClick }) {
  function getEls(location) {
    return els
      .filter(el => el.location === location)
      .map(el => (
        <DataSource
          key={el.id}
          name={el.name}
          isSelected={el.name === selected?.name}
          isOnline={el.isOnline}
          onClick={name => onClick(els.find(el => el.name === name))}
        />
      ));
  }

  const indoorStations = getEls('indoor');
  const outdoorStations = getEls('outdoor');

  return (
    <DataSourcesContainer name="Devices">
      {!indoorStations.length ? null : (
        <>

          {indoorStations}
        </>
      )}
      {!outdoorStations.length ? null : (
        <>

          {outdoorStations}
        </>
      )}
    </DataSourcesContainer>
  );
}

function SoilDataLoggers({ els, selected, onClick }) {
  function getEls() {
    return els.map(el => (
      <DataSource
        key={el.id}
        name={el.name}
        isSelected={el.name === selected?.name}
        isOnline={el.isOnline}
        onClick={name => onClick(els.find(el => el.name === name))}
      />
    ));
  }

  return !els.length ? null : (
    <DataSourcesContainer name="Devices">
      {getEls()}
    </DataSourcesContainer>
  );
}

function DataSourcesContainer({ name, children }) {
  return (
    <div className="DataSourcesContainer list-container">
      <div className="title list-element">{name}</div>
      {children}
    </div>
  );
}

function DataSource({ name, isSelected, isOnline, onClick }) {
  return (
    <button
      className={'DataSource list-element' + (isSelected ? ' selected' : '')}
      onClick={() => onClick(name)}
    >
      <div className="name">{name}</div>
      <OnlineIndicator isOnline={isOnline} />
    </button>
  );
}

function OnlineIndicator({ isOnline }) {
  return (
    <div className={'OnlineIndicator' + (isOnline ? '' : ' offline')}></div>
  );
}

function MobileBottomBar({ view, dataSource, isExpanded, onClick }) {
  function DataSource({ name, isOnline }) {
    return (
      <div className="DataSource">
        <div className="name">{name}</div>
        <OnlineIndicator isOnline={isOnline} />
      </div>
    );
  }

  function ExpandIcon({ isExpanded }) {
    return !isExpanded ? <MenuIcon /> : <CloseIcon />;
  }

  return (
    <button className="MobileBottomBar" onClick={onClick}>
      <div className="view">{view}</div>
      <div className="divider"></div>
      <DataSource name={dataSource.name} isOnline={dataSource.isOnline} />
      <div className="divider"></div>
      <ExpandIcon isExpanded={isExpanded} />
    </button>
  );
}

export default Sidebar;
