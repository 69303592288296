import './styles/ChartSoil.css';
import RegSkeleton from 'components/layouts/RegSkeleton';

const DATA_TYPES = [
  {
    id: 'temperature',
    name: 'Temperature',
    unit: '°C',
    getDataFromHistoricData:data => data.temperature
  },
  {
    id: 'cation-exchange-capacity',
    name: 'Cation Exchange Capacity',
    unit: 'meq/100g',
    getDataFromHistoricData:data => data.cec
  },
  {
    id: 'diffuse-oxygen',
    name: 'Diffuse Oxygen',
    unit: 'mg/L',
    getDataFromHistoricData:data => data.dissolved_oxygen
  },
  {
    id: 'electroconductivity',
    name: 'Electroconductivity',
    unit: 'uS/cm',
    getDataFromHistoricData:data => data.ec
  },
  {
    id: 'salinity',
    name: 'Salinity',
    unit: 'ppt',
    getDataFromHistoricData:data => data.salinity
  },
  {
    id: 'water-level',
    name: 'Water Level',
    unit: 'm',
    getDataFromHistoricData:data => data.water_level
  },
  {
    id: 'ultrasonic-water-level',
    name: 'Ultrasonic Water Level',
    unit: 'm',
    getDataFromHistoricData:data => data.ultrasonic_level_gauge
  },
  {
    id: 'nitrogen',
    name: 'Nitrogen',
    unit: 'mg/L',
    getDataFromHistoricData:data => data.nitrogen
  },
  {
    id: 'phosphorus',
    name: 'Phosphorus',
    unit: 'mg/L',
    getDataFromHistoricData:data => data.phosphorus
  },
  {
    id: 'potassium',
    name: 'Potassium',
    unit: 'mg/L',
    getDataFromHistoricData:data => data.potassium
  },
  {
    id: 'ph',
    name: 'pH',
    unit: 'pH',
    getDataFromHistoricData:data => data.ph
  },
  {
    id: 'moisture',
    name: 'Moisture',
    unit: '%',
    getDataFromHistoricData:data => data.humidity
  }
];


function RegSoil({
  selectedDataSource,
  historicTime,
  dataType1,
  dataType2,
  historicData,
  onHistoricTimeChange,
  onDataTypeChange1,
  onDataTypeChange2,
  onUpdate
}) {
  function getHistoricChartData1() {
    if (historicData === undefined || historicData[selectedDataSource.id] === undefined) return [];

    try{
      let historicChartData1 = [];
      //if (dataType === 'barometric-pressure') {
        //historicChartData = historicData.barometricPressure;
      //} else {
        const getValueFromData = DATA_TYPES.find(
          el => el.id === dataType1
        ).getDataFromHistoricData;
  
        historicChartData1 = historicData[selectedDataSource.id].map(data => ({
          time: data.time,
          value: getValueFromData(data)
        }));
      //}
  
      return historicChartData1;
    }
    catch{
      return [];
    }

  }

  function getHistoricChartData2() {
    if (historicData === undefined || historicData[selectedDataSource.id] === undefined) return [];

    try{
      let historicChartData2 = [];
      //if (dataType === 'barometric-pressure') {
        //historicChartData = historicData.barometricPressure;
      //} else {
        const getValueFromData = DATA_TYPES.find(
          el => el.id === dataType2
        ).getDataFromHistoricData;
  
        historicChartData2 = historicData[selectedDataSource.id].map(data => ({
          time: data.time,
          value: getValueFromData(data)
        }));
      //}
  
      return historicChartData2;
    }
    catch{
      return [];
    }


  }

  
  return (
    <div className="ChartWeather">
      <RegSkeleton
        historicTime={historicTime}
        dataTypes={DATA_TYPES}
        selectedDataType1={dataType1}
        selectedDataType2={dataType2}
        historicData={historicData}
        historicChartData1={getHistoricChartData1()}
        historicChartData2={getHistoricChartData2()}
        onHistoricTimeChange={onHistoricTimeChange}
        onDataTypeChange1={onDataTypeChange1}
        onDataTypeChange2={onDataTypeChange2}
        onUpdate={onUpdate}
        sensorType={selectedDataSource?.sensortype}
      />
    </div>
  );
}

export default RegSoil;
