import { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import thi from 'modules/thi';
import './styles/RegressionChartCard.css';
import ChartJsResponsive from 'components/misc/ChartJsResponsive';
import { ReactComponent as UpdateIcon } from 'assets/img/update.svg';
import regression from 'regression';

function RegressionChartCard({ data1, data2, overlayState }) {
  const canvasRef = useRef();
  const chartRef = useRef();
  const colorsRef = useRef({
    font: {
      cssVar: '--color-font-chart'
    },
    grid: {
      cssVar: '--color-grid'
    },
    line: {
      cssVar: '--color-line'
    },
    thiLevel1: {
      cssVar: '--color-thi-level-1'
    },
    thiLevel2: {
      cssVar: '--color-thi-level-2'
    },
    thiLevel3: {
      cssVar: '--color-thi-level-3'
    },
    thiLevel4: {
      cssVar: '--color-thi-level-4'
    }
  });

  function getMinMax() {
    // console.log(data1);
    const { min, max } = data1.reduce(
      (prev, curr) => {
        if (typeof curr.value !== 'number') return prev;

        const next = { ...prev };
        if (curr.value < prev.min.value) {
          next.min.time = curr.time;
          next.min.value = curr.value;
        }
        if (curr.value > prev.max.value) {
          next.max.time = curr.time;
          next.max.value = curr.value;
        }

        return next;
      },
      {
        min: { time: null, value: Infinity },
        max: { time: null, value: -Infinity },
      }
    );
    
    // const result= [min, max];
    // const x_min=result[0];
    // const x_max=result[1];
    // // const x_min_fr=x_min.map(d=> d.value);

    // console.log(x_min)
    // const x_min=min.map(d=> d.value)
    // const x_max=max.map(d=> d.value)

    // const result= [x_min, x_max]
    // const result= [min, max]


    return [min, max];
  }

  function getRegression() {

    const regress1 = data1.map(d => d.value);
    const regress2 = data2.map(d => d.value);

    try {
      let i=0;
      let count = regress1.length;
      let line_data=[];
      
      while (i<count) {
        var plot = [regress1[i], regress2[i]];
        line_data.push(plot);
        i++
      }

      // console.log('line data')
      // console.log(line_data)
      
      const result = regression.linear(
        line_data
      );

      return result
      
    } catch (error) {
      console.error(error);
    }

  }

  function myFixed(x, d) {
    if (!d) return x.toFixed(d); // don't go wrong if no decimal
    return x.toFixed(d).replace(/\.?0+$/, '');
  }

  const minmaxobj = getMinMax();

  // console.log(minmax)
  
  const minmax= minmaxobj.map(d=> d.value)

  const x_min = minmax[0];
  const x_max = minmax[1];

  const regress = getRegression();
  
  const y1=myFixed((x_min*(regress.equation[0])) + regress.equation[1],2);
  const y2=myFixed((x_max*(regress.equation[0])) + regress.equation[1],2);
  // console.log(y1)


  useEffect(() => {
    // Get CSS colors
    for (const el in colorsRef.current) {
      colorsRef.current[el].value = getComputedStyle(canvasRef.current)
        .getPropertyValue(colorsRef.current[el].cssVar)
        .trim();
    }



    // Init chart.
    const ctx = canvasRef.current.getContext('2d');
    chartRef.current = new Chart(ctx, {
      data: {
        labels: data1.map(el => el.value),
        datasets: [
          {
            type: 'scatter',
            data: data2.map(el => el.value),
            backgroundColor: colorsRef.current.line.value,
            borderColor: colorsRef.current.line.value,
            tension: 0.1,
            borderWidth: 1,
            pointRadius: 2
          },
          {
            type: 'line',
            data: [{
              x:x_min,
              y:y1
            },{
              x:x_max,
              y:y2
            }],
            backgroundColor: colorsRef.current.line.value,
            borderColor: colorsRef.current.line.value,
            tension: 0.1,
            borderWidth: 1,
            pointRadius: 0
          }
        ]
      },
      options: {
        plugins: {
          legend: { display: false },
          tooltip: {
            displayColors: false,
            titleFont: { size: 16 },
            bodyFont: { size: 16 }
          }
        },
        scales: {
          x: {
            ticks: { color: colorsRef.current.font.value, font: { size: 16 }, precision: 2 },
            grid: { color: colorsRef.current.grid.value }
          },
          y: {
            ticks: { color: colorsRef.current.font.value, font: { size: 16 }, precision: 2 },
            grid: { color: colorsRef.current.grid.value }
          }
        },
        maintainAspectRatio: false,
        interaction: { intersect: false }
      }
    },
    // {
    //   type: 'line',
    //   data: {
    //     labels: data1.map(el => el.value),
    //     datasets: [
    //       {
    //         data: data2.map(el => el.value),
    //         backgroundColor: colorsRef.current.line.value,
    //         borderColor: colorsRef.current.line.value,
    //         tension: 0.1,
    //         borderWidth: 1,
    //         pointRadius: 2
    //       }
    //     ]
    //   },
    //   options: {
    //     plugins: {
    //       legend: { display: false },
    //       tooltip: {
    //         displayColors: false,
    //         titleFont: { size: 16 },
    //         bodyFont: { size: 16 }
    //       }
    //     },
    //     scales: {
    //       x: {
    //         ticks: { color: colorsRef.current.font.value, font: { size: 16 } },
    //         grid: { color: colorsRef.current.grid.value }
    //       },
    //       y: {
    //         ticks: { color: colorsRef.current.font.value, font: { size: 16 } },
    //         grid: { color: colorsRef.current.grid.value }
    //       }
    //     },
    //     maintainAspectRatio: false,
    //     interaction: { intersect: false }
    //   }
    );

    return () => {
      chartRef.current.destroy();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {


    // Update chart data
    chartRef.current.data.labels = data1.map(el => el.value);
    chartRef.current.data.datasets[0].data = data2.map(el => el.value);
    chartRef.current.data.datasets[1].data = [{
      x:x_min,
      y:y1
    },{
      x:x_max,
      y:y2
    }];

    chartRef.current.options.plugins.tooltip.callbacks.label = function(tooltipItem, allData) {
     console.log(tooltipItem.dataIndex);
     return tooltipItem.dataset.data[tooltipItem.dataIndex];
    
    }



    chartRef.current.update();
    // eslint-disable-next-line
  }, [data1,data2]);

  function getDateStr(time) {
    // Example: 31/01 10:07
    const date = new Date(time);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hour = date.getHours().toString().padStart(2, '0');
    const minute = date.getMinutes().toString().padStart(2, '0');

    return `${day}/${month} ${hour}:${minute}`;
  }

  return (
    <div className="RegressionChartCard">
      <Overlay state={overlayState}>
        <ChartJsResponsive canvasRef={canvasRef} />
      </Overlay>
    </div>
  );
}

function Overlay({ state, children }) {
  function getStateElement() {
    function getElement() {
      if (state === 'usage-message') {
        return (
          <div className="message">
            Click on <UpdateIcon /> to display data.
          </div>
        );
      } else if (state === 'loading') {
        return <div className="spinner"></div>;
      }
    }

    if (!state) return;

    return <div className="state-element-container">{getElement()}</div>;
  }

  return (
    <div className="Overlay">
      {getStateElement()}
      <div className={'children-container' + (!state ? '' : ' translucent')}>
        {children}
      </div>
    </div>
  );
}

export default RegressionChartCard;
