import { useState, useRef } from 'react';
import './styles/CsvUploadCard.css';
import { ReactComponent as FileUploadIcon } from 'assets/img/file_download.svg';
import { ReactComponent as FileDownloadIcon_Dark } from 'assets/img/file_download_dark.svg';
import databaseapi from 'modules/databaseapi';
import React from 'react';

// data is a 2D array
function CsvUploadCard({ currentDeviceId ,Typeofsensor}) {
  const [csvUrl, setCsvUrl] = useState();
  const [darkMode, setDarkMode] = React.useState(true);
  const aRef = useRef();
  const fileInputRef = useRef(null);
  const [file, setFile] = useState(null);
  const[msg ,setMsg] = useState(null);





  const handleChange = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('file', event.target.files[0]);
    formData.append('device_id', currentDeviceId);
    formData.append('sensortype', Typeofsensor);
    const response = await databaseapi.postImportdata(formData);
    console.log(response)
    if (response.data.message === 'File uploaded successfully'){
      setMsg("Uploaded Sucessfully");
    }
    else{
      setMsg("Failed to Upload");
    }
    //console.log(currentDeviceId);
  };

  return (
    <div className="CsvUploadCard">
      <div className="title">Import data</div>
      <div className="body">
        <button onClick={() => fileInputRef.current.click()}>
          <input
          type="file"
          ref={fileInputRef}
          onChange={handleChange}
          style={{ display: 'none' }}
          accept=".csv"
          />
          <FileUploadIcon style={{ transform: 'rotate(180deg)'}}/>
        </button>
       
      </div>
      <div className={"message" +
    (msg === "Uploaded Sucessfully" ? '' : ' abnormal')} hidden>{msg}</div>
    </div>
  );
}

export default CsvUploadCard;
