import './styles/RegressionWeather.css';
import RegSkeleton from 'components/layouts/RegSkeleton';

function createDataType(id, unit, name, getDataFromHistoricData) {
  return { id, name, unit, getDataFromHistoricData };
}
const DATA_TYPES = [
  createDataType(
    'temperature',
    '°C',
    'Temperature',
    data => data.temperature.outside
  ),
  createDataType(
    'dew-point',
    '°C',
    'Dew point',
    data => data.temperature.dewPoint
  ),
  createDataType(
    'heat-index',
    '°C',
    'Heat index',
    data => data.temperature.heatIndex
  ),
  createDataType(
    'wind-chill',
    '°C',
    'Wind chill',
    data => data.temperature.windChill
  ),
  createDataType('humidity', '%', 'Humidity', data => data.humidity),
  createDataType('wind-speed', 'km/h', 'Wind speed', data => data.wind.speed),
  createDataType(
    'wind-direction',
    '°',
    'Wind direction',
    data => data.wind.direction
  ),
  createDataType('barometric-pressure', 'mm Hg', 'Barometric pressure' , data => data.barometricPressure),
  createDataType(
    'temperature-humidity-index',
    null,
    'THI',
    data => data.temperatureHumidityIndex
  ),
  createDataType('rainfall', 'mm', 'Rainfall', data => data.rainfall)
];

function RegWeather({
  selectedDataSource,
  historicTime,
  dataType1,
  dataType2,
  historicData,
  onHistoricTimeChange,
  onDataTypeChange1,
  onDataTypeChange2,
  onUpdate
}) {
  function getHistoricChartData1() {
    if (historicData === undefined || historicData[selectedDataSource.id] === undefined) return [];

    let historicChartData1 = [];
    //if (dataType === 'barometric-pressure') {
      //historicChartData = historicData.barometricPressure;
    //} else {
      const getValueFromData = DATA_TYPES.find(
        el => el.id === dataType1
      )?.getDataFromHistoricData;
      try {
      historicChartData1 = historicData[selectedDataSource.id].map(data => ({
        time: data.time,
        value: getValueFromData(data)
      }));}
      catch(err) {
        console.log("No Value for this parameter");
      }
    //}

    return historicChartData1;
  }

  function getHistoricChartData2() {
    if (historicData === undefined || historicData[selectedDataSource.id] === undefined) return [];

    let historicChartData2 = [];
    //if (dataType === 'barometric-pressure') {
      //historicChartData = historicData.barometricPressure;
    //} else {
      const getValueFromData = DATA_TYPES.find(
        el => el.id === dataType2
      )?.getDataFromHistoricData;
      try {
      historicChartData2 = historicData[selectedDataSource.id].map(data => ({
        time: data.time,
        value: getValueFromData(data)
      }));
    }
    catch(err) {
      console.log("No Value for this parameter");
    }   
    //}

    return historicChartData2;
  }

  
  return (
    <div className="RegressionWeather">
      <RegSkeleton
        historicTime={historicTime}
        dataTypes={DATA_TYPES}
        selectedDataType1={dataType1}
        selectedDataType2={dataType2}
        historicData={historicData}
        historicChartData1={getHistoricChartData1()}
        historicChartData2={getHistoricChartData2()}
        onHistoricTimeChange={onHistoricTimeChange}
        onDataTypeChange1={onDataTypeChange1}
        onDataTypeChange2={onDataTypeChange2}
        onUpdate={onUpdate}
      />
    </div>
  );
}

export default RegWeather;
