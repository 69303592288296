import './styles/AnovaSoil.css';
import AnovaSkeleton from 'components/layouts/AnovaSkeleton';

const DATA_TYPES = [
  {
    id: 'temperature',
    name: 'Temperature',
    unit: '°C',
    getDataFromHistoricData:data => data.temperature
  },
  {
    id: 'cation-exchange-capacity',
    name: 'Cation Exchange Capacity',
    unit: 'meq/100g',
    getDataFromHistoricData:data => data.cec
  },
  {
    id: 'diffuse-oxygen',
    name: 'Diffuse Oxygen',
    unit: 'mg/L',
    getDataFromHistoricData:data => data.dissolved_oxygen
  },
  {
    id: 'electroconductivity',
    name: 'Electroconductivity',
    unit: 'uS/cm',
    getDataFromHistoricData:data => data.ec
  },
  {
    id: 'salinity',
    name: 'Salinity',
    unit: 'ppt',
    getDataFromHistoricData:data => data.salinity
  },
  {
    id: 'water-level',
    name: 'Water Level',
    unit: 'm',
    getDataFromHistoricData:data => data.water_level
  },
  {
    id: 'ultrasonic-water-level',
    name: 'Ultrasonic Water Level',
    unit: 'm',
    getDataFromHistoricData:data => data.ultrasonic_level_gauge
  },
  {
    id: 'nitrogen',
    name: 'Nitrogen',
    unit: 'mg/L',
    getDataFromHistoricData:data => data.nitrogen
  },
  {
    id: 'phosphorus',
    name: 'Phosphorus',
    unit: 'mg/L',
    getDataFromHistoricData:data => data.phosphorus
  },
  {
    id: 'potassium',
    name: 'Potassium',
    unit: 'mg/L',
    getDataFromHistoricData:data => data.potassium
  },
  {
    id: 'ph',
    name: 'pH',
    unit: 'pH',
    getDataFromHistoricData:data => data.ph
  },
  {
    id: 'moisture',
    name: 'Moisture',
    unit: '%',
    getDataFromHistoricData:data => data.humidity
  }
];

function AnovaSoil({
  selectedDataSource,
  historicTime,
  dataType,
  historicData,
  historicData1,
  historicData2,
  historicData3,
  historicData4,
  onHistoricTimeChange,
  onDataTypeChange,
  onSave1,
  onSave2,
  onSave3,
  onSave4,
  sensorType
}) {

  function getTreat1Data() {

    if (historicData1 === undefined || historicData1[selectedDataSource.id] === undefined ) return [];
    
    let treat1Data = [];

      const getValueFromData = DATA_TYPES.find(
        el => el.id === dataType
      ).getDataFromHistoricData;

      treat1Data = historicData1[selectedDataSource.id].map(data => ({
        time: data.time,
        value: getValueFromData(data)
      }));
    
   
    return treat1Data;
  }

  function getTreat2Data() {
    if (historicData2 === undefined || historicData2[selectedDataSource.id] === undefined ) return [];

    let treat2Data = [];

      const getValueFromData = DATA_TYPES.find(
        el => el.id === dataType
      ).getDataFromHistoricData;

      treat2Data = historicData2[selectedDataSource.id].map(data => ({
        time: data.time,
        value: getValueFromData(data)
      }));
    

    return treat2Data;
  }

  function getTreat3Data() {
    if (historicData3 === undefined || historicData3[selectedDataSource.id] === undefined ) return [];

    let treat3Data = [];

      const getValueFromData = DATA_TYPES.find(
        el => el.id === dataType
      ).getDataFromHistoricData;

      treat3Data = historicData3[selectedDataSource.id].map(data => ({
        time: data.time,
        value: getValueFromData(data)
      }));
    

    return treat3Data;
  }

  function getTreat4Data() {
    if (historicData4 === undefined || historicData4[selectedDataSource.id] === undefined ) return [];
    let treat4Data = [];

      const getValueFromData = DATA_TYPES.find(
        el => el.id === dataType
      ).getDataFromHistoricData;

      treat4Data = historicData4[selectedDataSource.id].map(data => ({
        time: data.time,
        value: getValueFromData(data)
      }));
    

    return treat4Data;
  }
  
  return (
    <div className="AnovaSoil">
      <AnovaSkeleton
        historicTime={historicTime}
        dataTypes={DATA_TYPES}
        selectedDataType={dataType}
        treat1Data={getTreat1Data()}
        treat2Data={getTreat2Data()}
        treat3Data={getTreat3Data()}
        treat4Data={getTreat4Data()}
        onHistoricTimeChange={onHistoricTimeChange}
        onDataTypeChange={onDataTypeChange}
        onSave1={onSave1}
        onSave2={onSave2}
        onSave3={onSave3}
        onSave4={onSave4}
        sensorType={selectedDataSource?.sensortype}
      />
    </div>
  );
}

export default AnovaSoil;
