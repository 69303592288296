import { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import './styles/TemperatureCard.css';
import Card from 'components/misc/Card';
import ChartJsResponsive from 'components/misc/ChartJsResponsive';

function TemperatureCard({ outside, dewPoint, heatIndex, windChill }) {
  const canvasRef = useRef();
  const chartRef = useRef();

  useEffect(() => {
    function remToPx(str) {
      const rootFontSizePx = parseFloat(
        getComputedStyle(document.documentElement)
          .getPropertyValue('font-size')
          .split('px')[0]
      );
      const remValue = parseFloat(str.split('rem')[0]);

      return remValue * rootFontSizePx;
    }

    // Get vars from CSS.
    const [
      colorFont,
      colorGrid,
      colorOutside,
      colorDewPoint,
      colorHeadIndex,
      colorWindChill,
      fontSizeScales,
      fontSizeOverlayValues
    ] = [
      '--color-font',
      '--color-grid',
      '--color-outside',
      '--color-dew-point',
      '--color-heat-index',
      '--color-wind-chill',
      '--font-size-scales',
      '--font-size-overlay-values'
    ].map(el =>
      getComputedStyle(canvasRef.current).getPropertyValue(el).trim()
    );
    const fontSizeScalesPx = remToPx(fontSizeScales);
    const fontSizeOverlayValuesPx = remToPx(fontSizeOverlayValues);

    // Init chart.
    const ctx = canvasRef.current.getContext('2d');
    const alwaysShowTooltip = {
      id: 'alwaysShowTooltip',
      afterDraw(chart, args, options) {
        const { ctx } = chart;
        ctx.save();

        chart.data.datasets.forEach((dataset, datasetIdx) => {
          chart
            .getDatasetMeta(datasetIdx)
            .data.forEach((datapoint, datapointIdx) => {
              const { x, y } = datapoint.tooltipPosition();
              const value =
                chart.data.datasets[datasetIdx].data[datapointIdx] ?? '';
              ctx.font = `${fontSizeOverlayValuesPx}px Roboto`;
              ctx.fillStyle = 'white';
              ctx.fillText(value, x - ctx.measureText(value).width / 2, y+20);
              ctx.restore();
            });
        });
      }
    };
    chartRef.current = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: ['Outside', 'Dew point', 'Heat index', 'Wind chill'],
        datasets: [
          {
            data: [outside, dewPoint, heatIndex, windChill],
            backgroundColor: [
              colorOutside,
              colorDewPoint,
              colorHeadIndex,
              colorWindChill
            ],
            borderRadius: 16
          }
        ]
      },
      options: {
        plugins: {
          legend: { display: false },
          tooltip: { enabled: false }
        },
        scales: {
          x: {
            ticks: { color: colorFont, font: { size: fontSizeScalesPx } },
            grid: { color: colorGrid }
          },
          y: {
            // min: 20,
            ticks: { color: colorFont, font: { size: fontSizeScalesPx } },
            grid: { color: colorGrid }
          }
        },
        maintainAspectRatio: false,
        events: []
      },
      plugins: [alwaysShowTooltip]
    });

    return () => {
      chartRef.current.destroy();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    chartRef.current.data.datasets[0].data = [
      outside,
      dewPoint,
      heatIndex,
      windChill
    ];
    chartRef.current.update();
  }, [outside, dewPoint, heatIndex, windChill]);

  return (
    <div className="TemperatureCard">
      <Card title="Temperatures (°C)">
        <ChartJsResponsive canvasRef={canvasRef} />
      </Card>
    </div>
  );
}

export default TemperatureCard;
