import './styles/HomeLivestock.css';
import HomeSkeleton from 'components/layouts/HomeSkeleton';
//import BatteryLevelHeaderCard from 'components/misc/BatteryLevelHeaderCard';
import LastUpdatedTimeHeaderCard from 'components/misc/LastUpdatedTimeHeaderCard';
import Card from 'components/misc/Card';
import StraightGauge from 'components/misc/StraightGauge';
import GaugeChart from 'components/misc/GaugeChart';
import MapCard from 'components/misc/MapCard';
import { ReactComponent as BatteryFullIcon } from 'assets/img/battery_full.svg';
import { ReactComponent as Battery1BarIcon } from 'assets/img/battery_1_bar.svg';

// TODO: use data prop to update child data
function HomeLivestock({ currentSoil,deviceLocation,sitePlantations}) {

  
  const STATION_ONLINE_TIME_THRESHOLD = 30 * 60 * 10000
  return (
    <div className="HomeLivestock">
      <HomeSkeleton
        header={
          <>
            
            <LastUpdatedTimeHeaderCard time={new Date()} />
          </>
        }
        body={
          <>
          <MapCard
            lat={2.39047}
            long={103.80053}
            deviceRadius={0}
            plant_type={sitePlantations?.plant_type}
            tree_number={sitePlantations?.tree_number}
            animal_number={sitePlantations?.animal_number}
            hectarage={sitePlantations?.hectarage}
            site_manager={sitePlantations?.site_manager}
            staff_number={sitePlantations?.staff_number}
            device_type={deviceLocation?.sensortype}
            device_ID={currentSoil?.device_id}

            />

          </>
        }
      />
    </div>
  );
}




export default HomeLivestock;
