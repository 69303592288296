import './styles/HistoricTimeSelectHeaderCard.css';
import { ReactComponent as RefreshIcon } from 'assets/img/update.svg';

function HistoricTimeSelectHeaderCard({
  historicTime,
  isDisabled,
  onChange,
  onUpdateClick
}) {
  function handleStartTimeInputChange(e) {
    const newHistoricTime = { ...historicTime };
    newHistoricTime.start = new Date(e.target.value).getTime();
    console.log(newHistoricTime)
    onChange(newHistoricTime);
  }

  function handleDurationSelectChange(e) {
    const newHistoricTime = { ...historicTime };
    newHistoricTime.duration = parseInt(e.target.value);
    
    onChange(newHistoricTime);
  }

  return (
    <div className="HistoricTimeSelectHeaderCard">
      <StartTime
        value={historicTime.start}
        isDisabled={isDisabled}
        onChange={handleStartTimeInputChange}
      />
      <div className="right">
        <Duration
          value={historicTime.duration}
          isDisabled={isDisabled}
          onChange={handleDurationSelectChange}
        />
        <UpdateButton isDisabled={isDisabled} onClick={onUpdateClick} />
      </div>
    </div>
  );
}

function StartTime({ value, isDisabled, onChange }) {
  function getInputValue() {
    if (Number.isNaN(value)) return '';

    // Example: 2022-02-16T16:34
    const date = new Date(value);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hour = date.getHours().toString().padStart(2, '0');
    const minute = date.getMinutes().toString().padStart(2, '0');

    return `${year}-${month}-${day}T${hour}:${minute}`;
  }

  return (
    <div className="StartTime">
      <div className="top">
        <div className="label">Start time</div>
        <div className="usage-message">click the calendar icon</div>
      </div>
      <input
        type="datetime-local"
        value={getInputValue()}
        disabled={isDisabled}
        onChange={onChange}
      />
    </div>
  );
}

function Duration({ value, isDisabled, onChange }) {
  function getOptions() {
    const options = [];
    for (let i = 0; i < 24; i++) {
      options.push(<option key={i + 1}>{i + 1}</option>);
    }

    return options;
  }

  return (
    <div className="Duration">
      <div className="label">Duration (hours)</div>
      <select value={value} disabled={isDisabled} onChange={onChange}>
        {getOptions()}
      </select>
    </div>
  );
}

function UpdateButton({ isDisabled, onClick }) {
  return (
    <button className="UpdateButton" disabled={isDisabled} onClick={onClick}>
      <RefreshIcon />
    </button>
  );
}

export default HistoricTimeSelectHeaderCard;
