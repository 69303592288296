import './styles/AnovaInfoCard.css';
import anova  from 'anova';

function AnovaInfoCard({ treat1Data, treat2Data, treat3Data, treat4Data }) {
  function getAnova() {
    // console.log("treat1Data")
    // console.log(treat1Data)
    // console.log(data1)
    const data1 = treat1Data.map(d => d.value);
    const data2 = treat2Data.map(d => d.value);
    const data3 = treat3Data.map(d => d.value);
    const data4 = treat4Data.map(d => d.value);
      
    try{
      // var samples = [[1,5,7,4,2], [2,4,3,7,8,3]];
      var samples = [data1, data2, data3];
      // console.log(typeof(treat1Data.value));
      // console.log(anova.test(samples));
      var ss = anova.SS(samples);
      console.log('ss')
      console.log(ss);
      var df = anova.DF(samples);
      console.log('df')
      console.log(df);
      var ms = anova.MS(samples);
      console.log('ms')
      console.log(ms);
      var f=ms.treatment/ms.residual
      console.log(f)
      const result=[ss,df,ms,f]
    
      // TODO calculate p-value and f-critical(or options for selection f-critical)
      // console.log(samples)
      return result
      
    } catch (error) {
      console.error(error);
    }

  }
  function myFixed(x, d) {
    if (!d) return x.toFixed(d); // don't go wrong if no decimal
    return x.toFixed(d).replace(/\.?0+$/, '');
  }

  
  const result = getAnova();

  return (
      
    <div className="AnovaInfoCard">
      <div className="title" >Anova Analysis</div>
      <div className="body">
        <br/>
      <span className= "category" >Sum of Squares</span>
        <div>SS between groups: {(result===undefined ? '' : result[0].treatment.toPrecision(1+4))}</div>
        <div>SS within groups: {(result===undefined ? '' : result[0].residual.toPrecision(1+4))}</div>
        <div>SS Total: {(result===undefined ? '' : result[0].total.toPrecision(1+4))}</div>
        <br/>
        <span className= "category" >Degrees of Freedom</span>
        <div>DF between groups: {(result===undefined ? '' : result[1].treatment)}</div>
        <div>DF within groups: {(result===undefined ? '' : result[1].residual)}</div>
        <div>DF Total: {(result===undefined ? '' : result[1].total)}</div>
        <br/>
        <span className= "category" >Mean Squares</span>
        <div>MS between groups: {(result===undefined ? '' : result[2].treatment.toPrecision(1+4))}</div>
        <div>MS within groups: {(result===undefined ? '' : result[2].residual.toPrecision(1+4))}</div>
        <br/>
        <span className= "category">F Value= {(result===undefined ? '' : result[3].toPrecision(1+4))}</span>
      </div>
    </div>
  );
}

export default AnovaInfoCard;
