import './styles/HomeSoil.css';
import HomeSkeleton from 'components/layouts/HomeSkeleton';
//import BatteryLevelHeaderCard from 'components/misc/BatteryLevelHeaderCard';
import LastUpdatedTimeHeaderCard from 'components/misc/LastUpdatedTimeHeaderCard';
import Card from 'components/misc/Card';
import StraightGauge from 'components/misc/StraightGauge';
import GaugeChart from 'components/misc/GaugeChart';
import MapCard from 'components/misc/MapCard';
import { ReactComponent as BatteryFullIcon } from 'assets/img/battery_full.svg';
import { ReactComponent as Battery1BarIcon } from 'assets/img/battery_1_bar.svg';

// TODO: use data prop to update child data
function HomeSoil({ currentSoil,deviceLocation,sitePlantations}) {

  const STATION_ONLINE_TIME_THRESHOLD = 30 * 60 * 10000
  return (
    <div className="HomeSoil">
      <HomeSkeleton
        header={
          <>
            <OnlineStatusHeaderCard value={new  Date(currentSoil?.datetime).getTime() > Date.now() - STATION_ONLINE_TIME_THRESHOLD} />
            <LastUpdatedTimeHeaderCard time={currentSoil?.datetime} />
          </>
        }
        body={
          <>
          <MapCard
            lat={deviceLocation?.lat}
            long={deviceLocation?.long}
            deviceRadius={deviceLocation?.radius}
            plant_type={sitePlantations?.plant_type}
            tree_number={sitePlantations?.tree_number}
            animal_number={sitePlantations?.animal_number}
            hectarage={sitePlantations?.hectarage}
            site_manager={sitePlantations?.site_manager}
            staff_number={sitePlantations?.staff_number}
            device_type={deviceLocation?.sensortype}
            device_ID={currentSoil?.device_id}
Card
            />
            <div className='parameters'>  
            <TemperatureCard value={currentSoil?.temperature}/>
            <WaterLevelCard value={currentSoil?.water_level} />
            <SalinityCard value={currentSoil?.salinity} />
            <PhCard value={currentSoil?.ph} />
            <CationExchangCapacityCard value={currentSoil?.cec} />
            <DiffuseOxygenCard value={currentSoil?.dissolved_oxygen} type={currentSoil?.type} />
            <ElectroconductivityCard value={currentSoil?.ec} />
            <NpkCard name={"Nitrogen"} value={currentSoil?.nitrogen} />
            <NpkCard name={"Phosphorus"} value={currentSoil?.phosphorus} />
            <NpkCard name={"Potassium"} value={currentSoil?.potassium} />
            <MoistureCard value={currentSoil?.humidity} />
            <UltrasonicWaterLevelCard value={currentSoil?.ultrasonic_level_gauge} />
            </div>    
          </>
        }
      />
    </div>
  );
}

function TemperatureCard({ value }) {
  if (value===null){
    return (
      <div hidden>
      </div>
    )
  }
  
  return (
    <div className={"temperature" +
    (value <= 40 ? '' : ' abnormal')}  >
    <StraightGaugeCard
      title="Soil Temperature"
      isVertical
      value={value}
      min={-20}
      max={60}
      unit="°C"
    />
    </div>
  );
}

function CationExchangCapacityCard({ value }) {
  if (value===null){
    return (
      <div hidden>
      </div>
    )
  }
  return (
    <div className={"cec" +
    (value <= 60 ? '' : ' abnormal')}>
    <GaugeCard
      title="Soil CEC"
      value={value}
      min={0}
      max={100}
    />
    </div>
  );
}

function DiffuseOxygenCard({ value ,type}) {
  if (value===null){
    return (
      <div hidden>
      </div>
    )
  }
  return (
    <div className={"do" +
    (value <= 15 ? '' : ' abnormal') +(type === 'do_portable' ? ' portable' : '')}>
    <GaugeCard
      title="Soil Dissolved Oxygen"
      value={value}
      min={0}
      max={20}
      unit="mg/L"
    />
    </div>
  );
}

function ElectroconductivityCard({ value }) {
  if (value===null){
    return (
      <div hidden>
      </div>
    )
  }
  return (
    <div className={"ec" +
    (value <= 15000 ? '' : ' abnormal')}>
    <GaugeCard
      title="Soil EC"
      value={value}
      min={0}
      max={10000}
      unit="uS/cm"
    />
    </div>
  );
}

function SalinityCard({ value }) {

  
  if (value===null){
    return (
      <div hidden>
      </div>
    )
  }
  return (
    <div className={"salinity" +
    (value <= 1000 ? '' : ' abnormal')}>
    <StraightGaugeCard
      title="Soil Salinity"
      isVertical
      value={(value * 0.5 * 0.67).toFixed(2)}
      min={0}
      max={3350}
      unit="ppt"
    />
    </div>

  );
}

function WaterLevelCard({ value }) {
  if (value===null){
    return (
      <div hidden>
      </div>
    )
  }
  return (
    <div className={"waterlevel" +
    (value <= 3.7 ? '' : ' abnormal')}>
    <StraightGaugeCard
      title="Soil Water Level"
      isVertical
      value={value}
      min={0.0}
      max={5.0}
      unit="m"
    />
    </div>
  );
}

function UltrasonicWaterLevelCard({ value }) {
  if (value===null){
    return (
      <div hidden>
      </div>
    )
  }
  return (
    <div className={"waterlevel" +
    (value <= 3.7 ? '' : ' abnormal') + (' portable')}>
    <StraightGaugeCard
      title="Soil Water Level"
      isVertical
      value={value}
      min={0.0}
      max={5.0}
      unit="m"
    />
    </div>
  );
}

function NpkCard({ name,value }) {
  if (value===null){
    return (
      <div hidden>
      </div>
    )
  }
  return (
    <div className={"npk" +
    (value <= 1500 ? '' : ' abnormal')}>
    <GaugeCard title={name} value={value} min={1} max={2000} unit="mg/L" />
    </div>
  );
}

function PhCard({ value }) {
  if (value===null){
    return (
      <div hidden>
      </div>
    )
  }
  return (
    <div className={"phlevel" +
    (value >= 6.5 ? '' : ' abnormal')}>
    <StraightGaugeCard
      title="Soil pH"
      isVertical
      value={value}
      min={3.0}
      max={9.0}
      unit="pH"
    />
    
    </div>
  );
}

function MoistureCard({ value }) {
  if (value===null){
    return (
      <div hidden>
      </div>
    )
  }
  return (
    <div className={"moisture" +
    (value <= 80 ? '' : ' abnormal')}>
    <GaugeCard title="Soil Moisture" value={value} min={0} max={100} unit="%" />
    </div>
  );
}

function StraightGaugeCard({ title, isVertical, value, min, max, unit }) {
  return (
    <div className="StraightGaugeCard">
      <Card title={title}>
        <StraightGauge
          isVertical={isVertical}
          value={value}
          min={min}
          max={max}
          unit={unit}
        />
      </Card>
    </div>
  );
}

function GaugeCard({ title, value, min, max, unit }) {
  return (
    <div className="GaugeCard">
      <Card title={title}>
        <GaugeChart value={value} min={min} max={max} unit={unit} />
      </Card>
    </div>
  );
}

function OnlineStatusHeaderCard({ value }) {
  const isON = value;

  return (
    <div
      className={
        'OnlineStatusHeaderCard' +
        (isON === undefined ? 'low' : isON ? ' normal' : ' low')
      }
    >
      <div className="left">
        <div className="title">Online Status</div>
        <div className="value">{value === undefined ? 'Offline' : isON ? 'Online' :  'Offline'}</div>
      </div>
      {isON === undefined ? null : !isON ? (
        <Battery1BarIcon />
      ) : (
        <BatteryFullIcon />
      )}
    </div>
  );
}


export default HomeSoil;
