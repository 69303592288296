import { useState, useEffect } from 'react';
import farms from 'modules/farms';
import databaseapi from 'modules/databaseapi';
import weatherLink from 'modules/weather-link';
import './styles/Authenticated.css';
import Sidebar from 'components/layouts/Sidebar';
import HomeWeather from 'components/layouts/HomeWeather';
import HomeSoil from 'components/layouts/HomeSoil';
import HomeLivestock from 'components/layouts/HomeLivestock';
import HomeDevices from 'components/layouts/HomeDevices';
import ChartWeather from 'components/layouts/ChartWeather';
import ChartSoil from 'components/layouts/ChartSoil';
import WeatherSoilSwitch from 'components/layouts/WeatherSoilSwitch';
import AnovaWeather from 'components/layouts/AnovaWeather';
import AnovaSoil from 'components/layouts/AnovaSoil';
import RegWeather from 'components/layouts/RegWeather';
import RegSoil from 'components/layouts/RegSoil';
import { Collapse } from 'reactstrap';





const WEATHER_STATIONS = farms.selectedFarmData.weatherStations.map(el => ({
  ...el,
  isOnline: true,
  type: 'weather-station'
}));
const SOIL_DATA_LOGGERS = farms.selectedFarmData.soilDataLoggers.map(el => ({
  ...el,
  isOnline: true, // TODO: update this periodically
  type: 'soil-data-logger'
}));
const LIVESTOCK = farms.selectedFarmData.livestock.map(el => ({
  ...el,
  isOnline: true, // TODO: update this periodically
  type: 'livestock'
}));
// Max duration since last updated time to be considered online
const STATION_ONLINE_TIME_THRESHOLD = 30 * 60 * 10000;

function Authenticated({LoginUser}) {


  const [view, setView] = useState('Home');
  // "Data source" is a weather station or soil data logger
  const [dataSources, setDataSources] = useState({
    weatherStations: WEATHER_STATIONS,
    soilDataLoggers: SOIL_DATA_LOGGERS,
    selected: WEATHER_STATIONS[0]
  });

  const [currentWeather, setCurrentWeather] = useState();
  const [currentSoil, setCurrentSoil] = useState();
  const [currentDevicelocation, setCurrentDevicelocation] = useState();
  const [currentPlantation, setCurrentPlantation] = useState();
  const [currentDeviceinfo, setCurrentDeviceinfo] = useState();
  const [prevThi24hrs, setPrevThi24hrs] = useState();

  const [onedevicetype, setOnedevicetype] = useState(true);
  const [savedHistoricData, setSavedHistoricData] = useState(); 

  const [weatherHistoricTime, setWeatherHistoricTime] = useState({
    start: Date.now() - 24 * 60 * 60 * 1000,
    duration: 24
  });
  const [weatherHistoricDataType, setWeatherHistoricDataType] =
    useState('temperature');
  const [weatherHistoricData, setWeatherHistoricData] = useState();
  const [soilHistoricTime, setSoilHistoricTime] = useState({
    start: Date.now() - 24 * 60 * 60 * 1000,
    duration: 24
  });
  const [soilHistoricDataType, setSoilHistoricDataType] =
    useState('temperature');
  const [soilHistoricData, setSoilHistoricData] = useState();

  const [regressionHistoricDataType1, setRegressionHistoricDataType1] =
  useState('temperature');
  const [regressionHistoricDataType2, setRegressionHistoricDataType2] =
  useState('humidity');

  // separated data pull for ANOVA Weather
  const [savedWeatherData1, setSavedWeatherData1] = useState(); 
  const [savedWeatherData2, setSavedWeatherData2] = useState();
  const [savedWeatherData3, setSavedWeatherData3] = useState();
  const [savedWeatherData4, setSavedWeatherData4] = useState();

  // separated data pull for ANOVA Soil
  const [savedSoilData1, setSavedSoilData1] = useState(); 
  const [savedSoilData2, setSavedSoilData2] = useState();
  const [savedSoilData3, setSavedSoilData3] = useState();
  const [savedSoilData4, setSavedSoilData4] = useState();

   // get devices
  useEffect(() => {
    

    const fetchData = async () => {
      // get the data from the api
      var soil_sensors = await databaseapi.getSoilsensors(LoginUser);
      var weather_stations= await databaseapi.getWeatherstations(LoginUser);
      var plantations= await databaseapi.getPlantation(LoginUser);

      var all_devices = soil_sensors.concat(weather_stations);

      var current_Weatherdata = await databaseapi.getCurrentweather(LoginUser);
      var current_Soildata = await databaseapi.getCurrentsoil(LoginUser);
      var current_Deviceinfo = await databaseapi.getDeviceinfo(LoginUser);
      
      // convert the data to json
      //console.log(new  Date(current_Weatherdata?.[current_Weatherdata.findIndex((element) => element.device_id === weather_stations[0].id)].datetime).getTime());
      setOnedevicetype(false);
      console.log(soil_sensors.length)

      if (soil_sensors.length===0 && weather_stations.length ===0){
        setOnedevicetype(true);
        setDataSources({
          weatherStations: LIVESTOCK,
          soilDataLoggers: LIVESTOCK,
          selected: LIVESTOCK[0]});
        
      }

      else if (soil_sensors.length===0 || soil_sensors[0].id===0){
        soil_sensors=farms.selectedFarmData.emptyLoggers.map(el => ({
          ...el,
          isOnline: false, // TODO: update this periodically
          type: 'soil-data-logger'
        }));
        setOnedevicetype(true);
        setDataSources({
          weatherStations: weather_stations.map(el => ({
            ...el,
            isOnline: new  Date(current_Weatherdata[[current_Weatherdata.findIndex((element) => element.device_id === el.id)]]?.datetime).getTime() >Date.now() - STATION_ONLINE_TIME_THRESHOLD, // TODO: update this periodically
            type: 'weather-station'
          })),
          soilDataLoggers: soil_sensors.map(el => ({
            ...el,
            isOnline: new  Date(current_Soildata[[current_Soildata.findIndex((element) => element.device_id === el.id)]]?.datetime).getTime() >Date.now() - STATION_ONLINE_TIME_THRESHOLD, // TODO: update this periodically
            type: 'soil-data-logger'
          })),
          selected: weather_stations.map(el => ({
            ...el,
            isOnline: new  Date(current_Weatherdata[[current_Weatherdata.findIndex((element) => element.device_id === el.id)]]?.datetime).getTime() >Date.now() - STATION_ONLINE_TIME_THRESHOLD, // TODO: update this periodically
            type: 'weather-station'
          }))[0]
        });

      }

      else if (weather_stations.length===0 || weather_stations[0].id===0){
        
        weather_stations=farms.selectedFarmData.emptyLoggers.map(el => ({
          ...el,
          isOnline: false,
          type: 'weather-station'
        }));

        setOnedevicetype(true);

        setDataSources({
          weatherStations: weather_stations.map(el => ({
            ...el,
            isOnline: new  Date(current_Weatherdata[[current_Weatherdata.findIndex((element) => element.device_id === el.id)]]?.datetime).getTime() >Date.now() - STATION_ONLINE_TIME_THRESHOLD, // TODO: update this periodically
            type: 'weather-station'
          })),
          soilDataLoggers: soil_sensors.map(el => ({
            ...el,
            isOnline: new  Date(current_Soildata[[current_Soildata.findIndex((element) => element.device_id === el.id)]]?.datetime).getTime() >Date.now() - STATION_ONLINE_TIME_THRESHOLD, // TODO: update this periodically
            type: 'soil-data-logger'
          })),
          selected: soil_sensors.map(el => ({
            ...el,
            isOnline: new  Date(current_Soildata[[current_Soildata.findIndex((element) => element.device_id === el.id)]]?.datetime).getTime() >Date.now() - STATION_ONLINE_TIME_THRESHOLD, // TODO: update this periodically
            type: 'soil-data-logger'
          }))[0]
        });
        

        
        console.log(weather_stations[0].id);

      }


      else{
        setDataSources({
          weatherStations: weather_stations.map(el => ({
            ...el,
            isOnline: new  Date(current_Weatherdata[[current_Weatherdata.findIndex((element) => element.device_id === el.id)]]?.datetime).getTime() >Date.now() - STATION_ONLINE_TIME_THRESHOLD, // TODO: update this periodically
            type: 'weather-station'
          })),
          soilDataLoggers: soil_sensors.map(el => ({
            ...el,
            isOnline: new  Date(current_Soildata[[current_Soildata.findIndex((element) => element.device_id === el.id)]]?.datetime).getTime() >Date.now() - STATION_ONLINE_TIME_THRESHOLD, // TODO: update this periodically
            type: 'soil-data-logger'
          })),
          selected: weather_stations.map(el => ({
            ...el,
            isOnline: new  Date(current_Weatherdata[[current_Weatherdata.findIndex((element) => element.device_id === el.id)]]?.datetime).getTime() >Date.now() - STATION_ONLINE_TIME_THRESHOLD, // TODO: update this periodically
            type: 'weather-station'
          }))[0]
        });

      }

      

      setCurrentDevicelocation(all_devices);
      setCurrentPlantation(plantations);
      setCurrentWeather(current_Weatherdata);
      setCurrentSoil(current_Soildata);
      setCurrentDeviceinfo(current_Deviceinfo);


    }
  
    // call the function
    fetchData()
    // make sure to catch any error
    .catch(fetchData());
    const interval = setInterval(async () => {
      console.log('This will run every 5mins!');
      setOnedevicetype(false);
      fetchData()
      // make sure to catch any error
      .catch(fetchData());
 
   }, 300000);

   // This is important, you must clear your interval when component unmounts
   return () => clearInterval(interval);
    }, []);






  // Init weatherLink event listeners
  //useEffect(() => {

    
    //weatherLink.on('current-data', data => {
      //setCurrentWeather(data);

      // Update weather stations online status
      //setDataSources(prev => {
        //const next = { ...prev };
        //for (const weatherStation of next.weatherStations) {
          //weatherStation.isOnline =
            //data[weatherStation.id]?.time >
            //Date.now() - STATION_ONLINE_TIME_THRESHOLD;
        //}

        //return next;
      //});
    //});
    //weatherLink.on('prev-thi-24hrs', data => {
      //setPrevThi24hrs(data);
    //});

    //return () => {
      //weatherLink.off('current-data');
      //weatherLink.off('prev-thi-24hrs');
    //};
  //}, []);




  async function handleChartWeatherUpdate(startTime, duration) {
    try {
      const historicData = await databaseapi.getHistoricWeather(LoginUser,
        startTime,
        duration
      );
      console.log(historicData)
      setWeatherHistoricData(historicData);
    } catch (error) {
      console.error(error);
    }
  }

  async function handleChartSoilUpdate(startTime, duration) {
    try {
      const historicData = await databaseapi.getHistoricSoil(LoginUser,
        startTime,
        duration
      );
      console.log(historicData)
      setSoilHistoricData(historicData);
    } catch (error) {
      console.error(error);
    }
  }
  
  async function saveTreatHistoricUpdate(startTime, duration) {
    try {
      const savedData = await databaseapi.getHistoricWeather(LoginUser,
        startTime,
        duration
      );
      setSavedHistoricData(savedData);
    } catch (error) {
      console.error(error);
    }
  }
//Weather Anova handle functions
  async function saveTreatWeatherUpdate1(startTime, duration) {
    try {
      const savedData1 = await databaseapi.getHistoricWeather(LoginUser,
        startTime,
        duration
      );
      setSavedWeatherData1(savedData1);
    } catch (error) {
      console.error(error);
    }
  }

  async function saveTreatWeatherUpdate2(startTime, duration) {
    try {
      const savedData2 = await databaseapi.getHistoricWeather(LoginUser,
        startTime,
        duration
      );
      setSavedWeatherData2(savedData2);
    } catch (error) {
      console.error(error);
    }
  }

  async function saveTreatWeatherUpdate3(startTime, duration) {
    try {
      const savedData3 = await databaseapi.getHistoricWeather(LoginUser,
        startTime,
        duration
      );
      setSavedWeatherData3(savedData3);
    } catch (error) {
      console.error(error);
    }
  }

  async function saveTreatWeatherUpdate4(startTime, duration) {
    
    try {
      const savedData4 = await databaseapi.getHistoricWeather(LoginUser,
        startTime,
        duration
      );
      setSavedWeatherData4(savedData4);
    } catch (error) {
      console.error(error);
    }
  }

//Soil Anova handle functions
  async function saveTreatSoilUpdate1(startTime, duration) {
    try {
      
      const savedData1 = await databaseapi.getHistoricSoil(LoginUser,
        startTime,
        duration
      );
      
      setSavedSoilData1(savedData1);
    } catch (error) {
      console.error(error);
    }
  }

  async function saveTreatSoilUpdate2(startTime, duration) {
    try {
      const savedData2 = await databaseapi.getHistoricSoil(LoginUser,
        startTime,
        duration
      );
      setSavedSoilData2(savedData2);
    } catch (error) {
      console.error(error);
    }
  }

  async function saveTreatSoilUpdate3(startTime, duration) {
    try {
      const savedData3 = await databaseapi.getHistoricSoil(LoginUser,
        startTime,
        duration
      );
      setSavedSoilData3(savedData3);
    } catch (error) {
      console.error(error);
    }
  }

  async function saveTreatSoilUpdate4(startTime, duration) {
    try {
      const savedData4 = await databaseapi.getHistoricSoil(LoginUser,
        startTime,
        duration
      );
      setSavedSoilData4(savedData4);
    } catch (error) {
      console.error(error);
    }
  }


  return (
    
    <div className="Authenticated" >


      <div className="Authenticated_side" >
      
      <Sidebar
        OneDevice={onedevicetype}
        selectedDataType={dataSources.selected.type}
        selectedView={view}
        weatherStations={dataSources.weatherStations}
        soilDataLoggers={dataSources.soilDataLoggers}
        selectedDataSource={dataSources.selected}
        onDataTypeViewClick={view =>setDataSources(prevDataSources => {


          const next = { ...prevDataSources };
          if  (view === 'Weather Stations') {
            next.selected = dataSources.weatherStations[0];
          }       
          else if ((view === 'Soil Sensors')){
            next.selected= dataSources.soilDataLoggers[0];
          }
          return next;
        })}
        onViewClick={view => setView(view)}
        onDataSourceClick={dataSource =>
          setDataSources(prev => ({ ...prev, selected: dataSource }))
        
        }
        user={LoginUser}
              />
      </div>

      <div className="body">
        {view === 'Home' ? (
          dataSources.selected.type === 'weather-station' ? (
            <HomeWeather
              isOutdoor={dataSources.selected.location === 'outdoor'}
              currentWeather={currentWeather?.[currentWeather.findIndex((element) => element.device_id === dataSources.selected.id)]}
              prevThi={prevThi24hrs?.[dataSources.selected.id]}
              deviceLocation={currentDevicelocation?.[currentDevicelocation.findIndex((element) => element.id === dataSources.selected.id)]}
              sitePlantations={currentPlantation?.[currentPlantation.findIndex((element) => element.id === dataSources.selected.id)]}
            />
          ) : dataSources.selected.type === 'soil-data-logger' ?(
            <HomeSoil
            currentSoil={currentSoil?.[currentSoil.findIndex((element) => element.device_id === dataSources.selected.id)]}
            deviceLocation={currentDevicelocation?.[currentDevicelocation.findIndex((element) => element.id === dataSources.selected.id)]}
            sitePlantations={currentPlantation?.[currentPlantation.findIndex((element) => element.id === dataSources.selected.id)]}
            />
          ):(
            <HomeLivestock
            currentSoil={currentSoil?.[currentSoil.findIndex((element) => element.device_id === dataSources.selected.id)]}
            deviceLocation={currentDevicelocation?.[currentDevicelocation.findIndex((element) => element.id === dataSources.selected.id)]}
            sitePlantations={currentPlantation?.[currentPlantation.findIndex((element) => element.id === dataSources.selected.id)]}
            />            
          )
        ) : view === 'Chart' ? (
          dataSources.selected.type === 'weather-station' ? (
          <ChartWeather
            selectedDataSource={dataSources.selected}
            historicTime={weatherHistoricTime}
            dataType={weatherHistoricDataType}
            historicData={weatherHistoricData}
            onHistoricTimeChange={time => setWeatherHistoricTime(time)}
            onDataTypeChange={dataType => setWeatherHistoricDataType(dataType)}
            onUpdate={handleChartWeatherUpdate}
            currentDeviceId={dataSources.selected.id}
          />
        ) : (
          <ChartSoil
            deviceLocation={currentDevicelocation?.[currentDevicelocation.findIndex((element) => element.id === dataSources.selected.id)]}
            selectedDataSourceId={dataSources.selected}
            historicTime={soilHistoricTime}
            dataType={soilHistoricDataType}
            historicData={soilHistoricData}
            onHistoricTimeChange={time => setSoilHistoricTime(time)}
            onDataTypeChange={dataType => setSoilHistoricDataType(dataType)}
            onUpdate={handleChartSoilUpdate}
            currentDeviceId={dataSources.selected.id}
          />
        )
      ): view === 'ANOVA' ? (
        dataSources.selected.type === 'weather-station' ? (

        <AnovaWeather
          selectedDataSource={dataSources.selected}
          historicTime={weatherHistoricTime}
          dataType={weatherHistoricDataType}
          historicData1={savedWeatherData1}
          historicData2={savedWeatherData2}
          historicData3={savedWeatherData3}
          historicData4={savedWeatherData4}
          onHistoricTimeChange={time => setWeatherHistoricTime(time)}
          onDataTypeChange={dataType => setWeatherHistoricDataType(dataType)}
          onSave1={saveTreatWeatherUpdate1}
          onSave2={saveTreatWeatherUpdate2}
          onSave3={saveTreatWeatherUpdate3}
          onSave4={saveTreatWeatherUpdate4}
        />
        ) : (

          <AnovaSoil
          selectedDataSource={dataSources.selected}
          historicTime={soilHistoricTime}
          dataType={soilHistoricDataType}
          historicData1={savedSoilData1}
          historicData2={savedSoilData2}
          historicData3={savedSoilData3}
          historicData4={savedSoilData4}
          onHistoricTimeChange={time => setSoilHistoricTime(time)}
          onDataTypeChange={dataType => setSoilHistoricDataType(dataType)}
          onSave1={saveTreatSoilUpdate1}
          onSave2={saveTreatSoilUpdate2}
          onSave3={saveTreatSoilUpdate3}
          onSave4={saveTreatSoilUpdate4}
          sensorType={dataSources.selected.type}
        />

        )

      ): view === 'Regression' ? (
        dataSources.selected.type === 'weather-station' ? (
        <RegWeather
        selectedDataSource={dataSources.selected}
        historicTime={weatherHistoricTime}
        dataType1={regressionHistoricDataType1}
        dataType2={regressionHistoricDataType2}
        historicData={weatherHistoricData}
        onHistoricTimeChange={time => setWeatherHistoricTime(time)}
        onDataTypeChange1={dataType1 => setRegressionHistoricDataType1(dataType1)}
        onDataTypeChange2={dataType2 => setRegressionHistoricDataType2(dataType2)}
        onUpdate={handleChartWeatherUpdate}
      />
      ) : (
        <RegSoil
        selectedDataSource={dataSources.selected}
        historicTime={soilHistoricTime}
        dataType1={regressionHistoricDataType1}
        dataType2={regressionHistoricDataType2}
        historicData={soilHistoricData}
        onHistoricTimeChange={time => setSoilHistoricTime(time)}
        onDataTypeChange1={dataType1 => setRegressionHistoricDataType1(dataType1)}
        onDataTypeChange2={dataType2 => setRegressionHistoricDataType2(dataType2)}
        onUpdate={handleChartSoilUpdate}
      />

      )

      ):(
        <HomeDevices

        devicesInfo={currentDeviceinfo?.filter(d => d.device_id === dataSources.selected.id)}
        />
      )

      }


        
      </div>
    </div>
  );
}

export default Authenticated;
