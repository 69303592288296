import './styles/HumidityCard.css';
import Card from 'components/misc/Card';
import GaugeChart from 'components/misc/GaugeChart';

function HumidityCard({ humidity }) {
  return (
    <div className={"HumidityCard" +
    (humidity >= 90 ? '' : ' abnormal')}>
      <Card title="Humidity">
        <GaugeChart value={humidity} min={0} max={100} unit={'%'} />
      </Card>
    </div>
  );
}

export default HumidityCard;
