import './styles/HomeDevices.css';
import HomeSkeleton from 'components/layouts/HomeSkeleton';
import Card from 'components/misc/Card';
import solarenergy from 'assets/img/solar-energy.png';
import meteorology from 'assets/img/meteorology.png';
import meter from 'assets/img/meter.png';
import fourgmodule from 'assets/img/4gmodule.png';
import transmitter from 'assets/img/transmitter.png';
import ultrasonic from 'assets/img/ultrasonic.png';
import dosensor from 'assets/img/dosensor.png';
import soilsensor from 'assets/img/soilsensor.png';
import liquidlevel from 'assets/img/liquidlevel.png';
import handheld from 'assets/img/handheld.png';
import consoleimg from 'assets/img/console.png';
import sensorsuite from 'assets/img/sensorsuite.png';
import anemometer from 'assets/img/anemometer.png';
import raincollector from 'assets/img/raincollector.png';
import temphum from 'assets/img/temphum.png';
import weatherlink from 'assets/img/weatherlink.png';
import solarradiation from 'assets/img/solarradiation.png';

// TODO: use data prop to update child data
function HomeDevices({ devicesInfo }) {
  console.log(devicesInfo);

  
  return (

    <div className="HomeDevices">
    <div className="HomeSkeleton">
      <div className="body">
      {
      
      devicesInfo?.map((el, i) => 
      <Card title={"Devices "+String(i+1)}>
      <img src={
        devicesInfo[i].name === 'Weather Station'? meteorology : 
        devicesInfo[i].name === 'Ultrasonic Sensor'? ultrasonic : 
        devicesInfo[i].name === 'Soil Sensor'? soilsensor : 
        devicesInfo[i].name === 'Liquid Level Sensor'? liquidlevel : 
        devicesInfo[i].name === 'HandHeld Reader' || devicesInfo[i].name === 'Handheld Reader'? handheld : 
        devicesInfo[i].name === 'Dissolved Oxygen Sensor'? dosensor : 
        devicesInfo[i].name === 'Data Transmitter'? transmitter :
        devicesInfo[i].name === 'Weather Station Console'? consoleimg : 
        devicesInfo[i].name === 'Sensor Suite'? sensorsuite : 
        devicesInfo[i].name === 'Anemometer'? anemometer :
        devicesInfo[i].name === 'Rain Collector'? raincollector :
        devicesInfo[i].name === 'Temp/Hum Sensor'? temphum :
        devicesInfo[i].name === 'Weather Link Live'? weatherlink : 
        devicesInfo[i].name === 'Solar Radiation Sensor'? solarradiation : 
        fourgmodule} alt="logo"/> 

      <div className='deviceinfo'>
            <div>Name: {devicesInfo[i].name}</div>
            <div>Processor: {devicesInfo[i].Processors}</div>
            <div>RAM: {devicesInfo[i].RAM} MB</div>
            <div>Power Supply: {devicesInfo[i].power_supply}</div>
            <div>Communication:  {devicesInfo[i].communication}</div>
            <div>Details: {devicesInfo[i].Other_Details}</div>
            <div>Brand: {devicesInfo[i].Brand}</div>
        </div>
    </Card>
      
      )

      }
        
        
      
    </div>
    </div>
    </div>
  );
}





export default HomeDevices;
