import { useState } from 'react';
import './styles/ChartSkeleton.css';
import HistoricTimeSelectHeaderCard from 'components/misc/HistoricTimeSelectHeaderCard';
import DataTypeSelectHeaderCard from 'components/misc/DataTypeSelectHeaderCard';
import HistoricChartCard from 'components/misc/HistoricChartCard';
import ChartStatisticsCard from 'components/misc/ChartStatisticsCard';
import CsvDownloadCard from 'components/misc/CsvDownloadCard';
import CsvUploadCard from 'components/misc/CsvUploadCard';

function ChartSkeleton({
  historicTime,
  dataTypes,
  selectedDataType,
  historicData,
  historicChartData,
  csvData,
  onHistoricTimeChange,
  onDataTypeChange,
  onUpdate,
  sensorType,
  currentDeviceId
}) {
  const [isLoading, setIsLoading] = useState(false);
  var locationArray = historicChartData.map(el => el.location_name)
 
  console.log(historicChartData);
  //filter parameters based on sensor types
  if (sensorType!==undefined){
    if (sensorType==='fixed_soil'){
      
      dataTypes=dataTypes.filter(function(ele){ 
        return ele.id !== 'ultrasonic-water-level' && ele.id !== 'location_name'; 
    });

    if (selectedDataType==='ultrasonic-water-level' ){
      selectedDataType='temperature';
      onDataTypeChange(selectedDataType);
    }
    }

    else if(sensorType==='npk_portable'){
      
      dataTypes=dataTypes.filter(function(ele){ 
        return ['temperature','cation-exchange-capacity','electroconductivity',
        'salinity','nitrogen','phosphorus','potassium','ph','moisture'].includes(ele.id) ; 
    });
    
    if (selectedDataType==='ultrasonic-water-level' || selectedDataType==='diffuse-oxygen' ){
      selectedDataType='temperature';
      onDataTypeChange(selectedDataType);
    }
    locationArray = historicChartData.map(el => el.location_name)
    
    }

    else if(sensorType==='ultrasonic'){
      locationArray = historicChartData.map(el => el.location_name)
      
      dataTypes=dataTypes.filter(function(ele){ 
        return ele.id === 'ultrasonic-water-level'; 
    });
    selectedDataType='ultrasonic-water-level';
    onDataTypeChange(selectedDataType);
    }

    else if(sensorType==='do_portable'){
      locationArray = historicChartData.map(el => el.location_name)
      
      dataTypes=dataTypes.filter(function(ele){ 
        return ele.id === 'diffuse-oxygen'; 
    });
    selectedDataType='diffuse-oxygen';
    onDataTypeChange(selectedDataType);
    }

  }

  //console.log(locationArray);
  

  async function handleUpdateClick() {
    setIsLoading(true);
    await onUpdate(historicTime.start, historicTime.duration);
    setIsLoading(false);
  }

  function getChartOverlayState() {

    let overlayState;
    if (isLoading) {
      overlayState = 'loading';
    } else {
      const isStartTimeSame =
        Math.floor(historicTime.start / (60 * 1000)) ===
        Math.floor(historicData?.time.start / (60 * 1000));
      const isDurationSame =
        historicData === undefined
          ? false
          : Math.floor(
              (historicData.time.end - historicData.time.start) /
                (60 * 60 * 1000)
            ) === historicTime.duration;
      if (!isStartTimeSame || !isDurationSame) {
        overlayState = 'usage-message';
      }
    }

    return overlayState;
  }

  return (
    <div className="ChartSkeleton">
      <div className="header">
        <HistoricTimeSelectHeaderCard
          historicTime={historicTime}
          isDisabled={isLoading}
          onChange={onHistoricTimeChange}
          onUpdateClick={handleUpdateClick}
        />
        <DataTypeSelectHeaderCard
          els={dataTypes}
          selected={selectedDataType}
          isDisabled={isLoading}
          onChange={onDataTypeChange}
        />
      </div>
      <div className="body">
        <HistoricChartCard
          data={historicChartData}
          isThi={selectedDataType === 'temperature-humidity-index'}
          overlayState={getChartOverlayState()}
          locationarray={locationArray}
          sensortype={sensorType}
        />
        <div className="bottom">
          <ChartStatisticsCard
            data={historicChartData}
            unit={dataTypes.find(el => el.id === selectedDataType).unit}
          />
          {sensorType === 'npk_portable' || sensorType === 'do_portable'  ? (
          <CsvUploadCard currentDeviceId={currentDeviceId} Typeofsensor={sensorType} />  
          ): (<></>)}
          <CsvDownloadCard filename={csvData?.filename} data={csvData?.data} />  
          
        </div>
      </div>
    </div>
  );
}

export default ChartSkeleton;
