import { useEffect, useRef ,useState } from 'react';
import Chart from 'chart.js/auto';
import thi from 'modules/thi';
import './styles/HistoricChartCard.css';
import ChartJsResponsive from 'components/misc/ChartJsResponsive';
import { ReactComponent as UpdateIcon } from 'assets/img/update.svg';

function HistoricChartCard({ data, isThi, overlayState ,locationarray,sensortype }) {
  const [locationArray, setlocationArray] = useState([]);
  const canvasRef = useRef();
  const chartRef = useRef();
  const colorsRef = useRef({
    font: {
      cssVar: '--color-font-chart'
    },
    grid: {
      cssVar: '--color-grid'
    },
    line: {
      cssVar: '--color-line'
    },
    thiLevel1: {
      cssVar: '--color-thi-level-1'
    },
    thiLevel2: {
      cssVar: '--color-thi-level-2'
    },
    thiLevel3: {
      cssVar: '--color-thi-level-3'
    },
    thiLevel4: {
      cssVar: '--color-thi-level-4'
    },
    bar:{
      cssVar: '--color-bar'
    }
  });
  


  useEffect(() => {
    // Get CSS colors
    for (const el in colorsRef.current) {
      colorsRef.current[el].value = getComputedStyle(canvasRef.current)
        .getPropertyValue(colorsRef.current[el].cssVar)
        .trim();
    }

    // Init chart.
    const ctx = canvasRef.current.getContext('2d');

    chartRef.current = new Chart(ctx, {
      type: 'line',
      data: {
        labels: data.map(el => getDateStr(el.time)),
        datasets: [
          {
            data: data.map(el => el.value),
            backgroundColor: colorsRef.current.line.value,
            borderColor: colorsRef.current.line.value,
            tension: 0.1,
            borderWidth: 1,
            pointRadius: 2
            
          },
          {
            data: data.map(el => el.value),
            backgroundColor: colorsRef.current.bar.value,
            borderColor: colorsRef.current.bar.value,
            tension: 0.1,
            borderWidth: 1,
            pointRadius: 2,
            type: 'bar'
            
          }

        ]
      },
      options: {
        plugins: {
          legend: { display: false },
          tooltip: {
            displayColors: false,
            titleFont: { size: 16 },
            bodyFont: { size: 16 },
            callbacks:{
              label: function(tooltipItem, allData) {

                if (locationArray.length  > 0){
                  return tooltipItem.dataset.data[tooltipItem.dataIndex] + " " +locationArray[tooltipItem.dataIndex];
                }

                return tooltipItem.dataset.data[tooltipItem.dataIndex];
              }
            }
          }
        },
        scales: {
          x: {
            ticks: { color: colorsRef.current.font.value, font: { size: 16 } },
            grid: { color: colorsRef.current.grid.value }
          },
          y: {
            ticks: { color: colorsRef.current.font.value, font: { size: 16 } },
            grid: { color: colorsRef.current.grid.value }
          }
        },
        maintainAspectRatio: false,
        interaction: { intersect: false }
      }
    });

    return () => {
      chartRef.current.destroy();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    function setAnnotations() {
      chartRef.current.options.plugins.annotation.annotations = {};
      if (data.length > 0 && isThi) {
        // Get visible stress categories
        const { min, max } = data.reduce(
          (prev, curr) => ({
            min: Math.min(prev.min, curr.value),
            max: Math.max(prev.max, curr.value)
          }),
          { min: Infinity, max: -Infinity }
        );
        const visibleStressCategories = [];
        for (const el of thi.STRESS_CATEGORIES) {
          if (el.max > min) visibleStressCategories.push(el);
          if (el.max >= max) break;
        }

        // Set box annotations
        const boxAnnotation = {
          type: 'box',
          borderWidth: 0,
          label: {
            enabled: true,
            color: colorsRef.current.font.value,
            font: { family: 'Roboto', size: 16, weight: 'normal' }
          },
          drawTime: 'beforeDatasetsDraw'
        };
        let nextMin = min - 0.5;
        for (const el of visibleStressCategories) {
          const yMax = Math.min(el.max, max) + 0.5;
          chartRef.current.options.plugins.annotation.annotations[el.name] = {
            ...boxAnnotation,
            yMin: nextMin,
            yMax,
            backgroundColor: 'rgba(0, 0, 0, 0)',
            borderColor: colorsRef.current[`thiLevel${el.level}`].value,
            borderWidth: 1,
            label: {
              ...boxAnnotation.label,
              content: el.name,
              color: colorsRef.current[`thiLevel${el.level}`].value
            }
          };
          nextMin = yMax;
        }
      }
    }

    // Update chart data
    chartRef.current.data.labels = data.map(el => getDateStr(el.time) );

    chartRef.current.data.datasets[0].data = data.map(el => el.value);
    chartRef.current.data.datasets[1].data = data.map(el => el.value);
    chartRef.current.options.plugins.tooltip.callbacks.label = function(tooltipItem, allData) {

      if (data.map(el => el.location_name)[tooltipItem.dataIndex] !== undefined){
          if (sensortype === "npk_portable" || sensortype === "do_portable" || sensortype === "ultrasonic"){
            return tooltipItem.dataset.data[tooltipItem.dataIndex] + " " +data.map(el => el.location_name)[tooltipItem.dataIndex];
          }
          else{
            return tooltipItem.dataset.data[tooltipItem.dataIndex] + "";
          }
      }

      return tooltipItem.dataset.data[tooltipItem.dataIndex];
    }

    setlocationArray(data.map(el => el.location_name));


    setAnnotations();

    chartRef.current.update();
    console.log(locationArray);
    // eslint-disable-next-line
  }, [data]);

  

  function getDateStr(time) {
    // Example: 31/01 10:07
    const date = new Date(time);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hour = date.getHours().toString().padStart(2, '0');
    const minute = date.getMinutes().toString().padStart(2, '0');

    return `${day}/${month} ${hour}:${minute}`;
  }

  return (
    <div className="HistoricChartCard">
      <Overlay state={overlayState}>
        <ChartJsResponsive canvasRef={canvasRef} />
      </Overlay>
    </div>
  );
}

function Overlay({ state, children }) {
  function getStateElement() {
    function getElement() {
      if (state === 'usage-message') {
        return (
          <div className="message">
            Click on <UpdateIcon /> to display data.
          </div>
        );
      } else if (state === 'loading') {
        return <div className="spinner"></div>;
      }
    }

    if (!state) return;

    return <div className="state-element-container">{getElement()}</div>;
  }

  return (
    <div className="Overlay">
      {getStateElement()}
      <div className={'children-container' + (!state ? '' : ' translucent')}>
        {children}
      </div>
    </div>
  );
}

export default HistoricChartCard;
