import './styles/HomeSkeleton.css';

function HomeSkeleton({ header, body ,map}) {
  return (
    <div className="HomeSkeleton">
      <div className="header">{header}</div>
      <div className="map">{map}</div>
      <div className="body">{body}</div>
    </div>
  );
}

export default HomeSkeleton;
