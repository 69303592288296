const axios = require('axios');
const url = "https://backend.felcra-iot.com";

async function isUserLoginValid(userName, passWord) {

  const res = await axios.get(url+"/authenticate", { params: { username: userName  , password:passWord } });
  const matched=res.data.Login;
  return matched;
}

async function getSoilsensors(userName) {
  var soilDataLoggers=[]
  const res = await axios.get(url+"/soilsensors", { params: { username: userName} });
  

  for (let i = 0; i < res.data.length; i++) {

    soilDataLoggers.push({name:res.data[i].devices_name,id:res.data[i].device_id,location: 'indoor',lat:res.data[i].location_coord_lat,long:res.data[i].location_coord_long,radius:res.data[i].radius,sensortype:res.data[i].type})
    
  }
  
  return soilDataLoggers;
}


async function getWeatherstations(userName) {
  var weatherStations=[]
  const res = await axios.get(url+"/weatherstations", { params: { username: userName} });
  

  for (let i = 0; i < res.data.length; i++) {

    weatherStations.push({name:res.data[i].site_name,id:res.data[i].device_id,location: 'outdoor',lat:res.data[i].location_coord_lat,long:res.data[i].location_coord_long,radius:res.data[i].radius,sensortype:res.data[i].type})
    
  }
  
  return weatherStations;
}


async function getPlantation(userName) {
  var plantation=[]
  const res = await axios.get(url+"/plantation", { params: { username: userName} });
  

  for (let i = 0; i < res.data.length; i++) {

    plantation.push({id:res.data[i].device_id,plant_type:res.data[i].plant_type,tree_number: res.data[i].tree_number ,animal_number:res.data[i].animal_number,hectarage:res.data[i].hectarage,site_manager:res.data[i].site_manager,staff_number:res.data[i].staff_number})
    
  }
  
  return plantation;
}


async function getCurrentweather(userName) {
  var currentdata=[]
  const res = await axios.get(url+"/currentweather", { params: { username: userName} });

  for (let i = 0; i < res.data.length; i++) {

    currentdata.push(res.data[i])
    
  }
  
  return currentdata;
}


async function getCurrentsoil(userName) {
  var currentdata=[]
  const res = await axios.get(url+"/currentsoil", { params: { username: userName} });
  for (let i = 0; i < res.data.length; i++) {
    currentdata.push(res.data[i])
  }
  return currentdata;
}

async function getDeviceinfo(userName) {
  var devicedata=[]
  const res = await axios.get(url+"/devicesinfo", { params: { username: userName} });
  for (let i = 0; i < res.data.length; i++) {
    devicedata.push(res.data[i])
  }
  return devicedata;
}


async function getHistoricWeather(userName,Starttime,Duration) {
  
  const res = await axios.get(url+"/historicweather", { params: { username: userName,starttime:Starttime ,duration:Duration } });

  var historicresult=res.data

  historicresult["time"]={ start: Starttime, end: Starttime + Duration * 60 * 60 * 1000 }
  return historicresult;
}

async function getHistoricSoil(userName,Starttime,Duration) {
  
  const res = await axios.get(url+"/historicsoil", { params: { username: userName,starttime:Starttime ,duration:Duration } });

  var historicresult=res.data

  historicresult["time"]={ start: Starttime, end: Starttime + Duration * 60 * 60 * 1000 }
  return historicresult;
}

async function postlocation(deviceID,longiTude,latiTude,locationName) {

  axios.post(url+"/postlocation",  {
    params: { locationname :locationName ,deviceid: deviceID,longitude:longiTude ,latitude:latiTude }
  })
  .then(response => {
    console.log(response.data);
    return "Location Updated Successfully.";
  })
  .catch(error => {
    return "Fail to Update Location";
  });
 
}

async function postImportdata(formData) {


  const response = await axios.post(url+"/importdata", formData, {
      headers: {
          'Content-Type': 'multipart/form-data'
      }
    });

  return response;
    
 
}

const databaseapi = { isUserLoginValid,getSoilsensors,getWeatherstations,
  getPlantation,getCurrentweather,getCurrentsoil,getDeviceinfo, getHistoricWeather,getHistoricSoil,postlocation,postImportdata};
export default databaseapi;
