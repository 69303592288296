import { useState, useRef } from 'react';
import './styles/CsvDownloadCard.css';
import { ReactComponent as FileDownloadIcon } from 'assets/img/file_download.svg';
import { ReactComponent as FileDownloadIcon_Dark } from 'assets/img/file_download_dark.svg';
import React from 'react';

// data is a 2D array
function CsvDownloadCard({ filename, data }) {
  const [csvUrl, setCsvUrl] = useState();
  const [darkMode, setDarkMode] = React.useState(true);
  const aRef = useRef();

  async function handleClick() {
    const csvStr = data.map(e => e.join(',')).join('\n');
    const csvUri = 'data:text/csv;charset=utf-8,' + csvStr;
    setCsvUrl(encodeURI(csvUri));

    // Delay before clicking (doesn't work without delay)
    await new Promise(resolve => setTimeout(resolve, 500));
    aRef.current.click();
  }

  return (
    <div className="CsvDownloadCard">
      <div className="title">Export data</div>
      <div className="body">
        <button disabled={!data} onClick={handleClick}>
          <FileDownloadIcon />
        </button>
      </div>
      <a
        style={{ display: 'none' }}
        ref={aRef}
        href={csvUrl}
        download={`${filename}.csv`}
      >
        download .csv
      </a>
    </div>
  );
}

export default CsvDownloadCard;
