import { useState, useEffect,createRef } from 'react';
import farms from 'modules/farms';
import 'App.css';
import Login from 'components/pages/Login';
import Authenticated from 'components/pages/Authenticated';
import { Button, Container, InputGroup } from 'reactstrap';
import { ThemeContext, themes } from './contexts/ThemeContext';
import React from 'react';
import ToggleDark from './components/toggleDark';
import ReactToPrint from 'react-to-print';
import { useScreenshot ,createFileName} from 'use-react-screenshot'
function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [username, setUsername] = useState('');
  const [darkMode, setDarkMode] = React.useState(true);

  const [fontStyle, setSelectedStyle] = useState('default');
  const [gap, setGap] = useState('default');

  const handleStyleChange = (e) => {
    setSelectedStyle(e.target.value);
    console.log(fontStyle);
  };
  const handleGapChange = (e) => {
    setGap(e.target.value);
    console.log(gap);
  };



  useEffect(() => {
    document.title = farms.selectedFarmData.appTitle;
  }, []);

  function handleLoggedIn(username) {
    setUsername(username);
    setIsAuthenticated(true);
  }

  function handleLoggedInUsername(username) {
    setUsername(username);
  }

  function refreshPage() {
    window.location.reload();
  }

  const ref = createRef(null);
  const [image, takeScreenShot] = useScreenshot({
    type: "image/jpeg",
    quality: 1.0
  });
  const download = (image, { name = "img", extension = "jpg" } = {}) => {
    const a = document.createElement("a");
    a.href = image;
    a.download = createFileName(extension, name);
    a.click();
  };

  const downloadScreenshot = () => takeScreenShot(ref.current).then(download);

  return (
    
    <div className={"App " + fontStyle} ref={ref}>

        <div class={"blur " + gap} >

        <div class="header">
       <ThemeContext.Consumer>

       {({ changeTheme }) => (
              
              <label class="switch">
              <input
              type="checkbox"
                color="link"
                onClick={() => {
                  setDarkMode(!darkMode);
                  changeTheme(darkMode ? themes.light : themes.dark);
                }}
              />
                
                
                <span className="slider round"></span>
                
              </label>
              
            )}

       </ThemeContext.Consumer>
       <select className="fontoption" value={fontStyle} onChange={handleStyleChange}>
        <option value="default">Font Style</option>
        <option value="times">Times New Roman</option>
        <option value="arial">Arial</option>
        <option value="lucida">Lucida Console</option>
      </select>

      <select className="gapoption" value={gap} onChange={handleGapChange}>
        <option value="default">Margin</option>
        <option value="nogap">No Gap</option>
        <option value="medium">Medium</option>
        <option value="large">Large</option>
      </select>

       <button className="screenshotbutton" onClick={downloadScreenshot}>
          Screenshot
        </button>
       {isAuthenticated ? (
        <button className="logoutbutton" type="submit" onClick={refreshPage}>Log Out</button>
        ): (
        
          <></>
          
        )}

       </div>
      {!isAuthenticated ? (
        <Login onLoggedIn={handleLoggedIn} />
      ) : (
        
        <Authenticated LoginUser={username}/>
        
      )}
      </div>
    </div>
  );
}

export default App;
