import { useState } from 'react';
import './styles/RegSkeleton.css';
import RegTimeSelectHeaderCard from 'components/misc/RegTimeSelectHeaderCard';
import RegDataTypeSelectHeaderCard1 from 'components/misc/RegDataTypeSelectHeaderCard1';
import RegDataTypeSelectHeaderCard2 from 'components/misc/RegDataTypeSelectHeaderCard2';
import RegressionChartCard from 'components/misc/RegressionChartCard';
import RegressionInfoCard from 'components/misc/RegressionInfoCard';

function RegSkeleton({
  historicTime,
  dataTypes,
  selectedDataType1,
  selectedDataType2,
  historicData,
  historicChartData1,
  historicChartData2,
  onHistoricTimeChange,
  onDataTypeChange1,
  onDataTypeChange2,
  onUpdate,
  sensorType
}) {

  console.log(sensorType)
  if (sensorType!==undefined){
    if (sensorType==='fixed_soil'){
      
      dataTypes=dataTypes.filter(function(ele){ 
        return ele.id !== 'ultrasonic-water-level' && ele.id !== 'location_name'; 
    });

    if (selectedDataType1==='ultrasonic-water-level' ){
      selectedDataType1='temperature';
      onDataTypeChange1(selectedDataType1);
    }
    if (selectedDataType2==='ultrasonic-water-level' ){
      selectedDataType2='temperature';
      onDataTypeChange2(selectedDataType2);
    }
    
    }

    else if(sensorType==='npk_portable'){
      
      dataTypes=dataTypes.filter(function(ele){ 
        return ['temperature','cation-exchange-capacity','electroconductivity',
        'salinity','nitrogen','phosphorus','potassium','ph','moisture'].includes(ele.id) ; 
    });
    
    if (selectedDataType1==='ultrasonic-water-level' || selectedDataType1==='diffuse-oxygen' ){
      selectedDataType1='temperature';
      onDataTypeChange1(selectedDataType1);
    }
    if (selectedDataType2==='ultrasonic-water-level' || selectedDataType2==='diffuse-oxygen' ){
      selectedDataType2='temperature';
      onDataTypeChange2(selectedDataType2);
    }
    
    }

    else if(sensorType==='ultrasonic'){

      
      dataTypes=dataTypes.filter(function(ele){ 
        return ele.id === 'ultrasonic-water-level'; 
    });
    selectedDataType1='ultrasonic-water-level';
    onDataTypeChange1(selectedDataType1);
    selectedDataType2='ultrasonic-water-level';
    onDataTypeChange2(selectedDataType2);
    }

    else if(sensorType==='do_portable'){

      
      dataTypes=dataTypes.filter(function(ele){ 
        return ele.id === 'diffuse-oxygen'; 
    });
    selectedDataType1='diffuse-oxygen';
    onDataTypeChange1(selectedDataType1);
    selectedDataType2='diffuse-oxygen';
    onDataTypeChange2(selectedDataType2);
    }

  }
  
  const [isLoading, setIsLoading] = useState(false);
  // const [gradient, setGradient] = useState(false);


  async function handleUpdateClick() {
    setIsLoading(true);
    await onUpdate(historicTime.start, historicTime.duration);
    setIsLoading(false);
  }

  function getChartOverlayState() {

    let overlayState;
    if (isLoading) {
      overlayState = 'loading';
    } else {
      const isStartTimeSame =
        Math.floor(historicTime.start / (60 * 1000)) ===
        Math.floor(historicData?.time.start / (60 * 1000));
      const isDurationSame =
        historicData === undefined
          ? false
          : Math.floor(
              (historicData.time.end - historicData.time.start) /
                (60 * 60 * 1000)
            ) === historicTime.duration;
      if (!isStartTimeSame || !isDurationSame) {
        overlayState = 'usage-message';
      }
    }

    return overlayState;
  }

  return (
    <div className="RegSkeleton">
      <div className="header">
        <div className='time'>
        <RegTimeSelectHeaderCard
          historicTime={historicTime}
          isDisabled={isLoading}
          onChange={onHistoricTimeChange}
          onUpdateClick={handleUpdateClick}
        />
        </div>
        <div className='data'>
        <RegDataTypeSelectHeaderCard1
          els={dataTypes}
          selected={selectedDataType1}
          isDisabled={isLoading}
          onChange={onDataTypeChange1}
        />
        </div>
        <div className='data'>
        <RegDataTypeSelectHeaderCard2
          els={dataTypes}
          selected={selectedDataType2}
          isDisabled={isLoading}
          onChange={onDataTypeChange2}
        />
        </div>
      </div>
      <div className="body">
        <RegressionChartCard
          data1={historicChartData1}
          data2={historicChartData2}

          overlayState={getChartOverlayState()}
        />
        </div>
        <div className="bottom">
          <RegressionInfoCard
            selected1={selectedDataType1}
            selected2={selectedDataType2}
            data1={historicChartData1}
            data2={historicChartData2}
          />
        </div>
      </div>
  
  );
}

export default RegSkeleton;
