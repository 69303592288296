import './styles/ChartStatisticsCard.css';

function ChartStatisticsCard({ data, unit }) {
  function getStats() {
    function getMinMaxAvg() {
      console.log(data);
      const { min, max, sum } = data.reduce(
        (prev, curr) => {
          if (typeof curr.value !== 'number') return prev;

          const next = { ...prev };
          if (curr.value < prev.min.value) {
            next.min.time = curr.time;
            next.min.value = curr.value;
          }
          if (curr.value > prev.max.value) {
            next.max.time = curr.time;
            next.max.value = curr.value;
          }
          next.sum += curr.value;

          return next;
        },
        {
          min: { time: null, value: Infinity },
          max: { time: null, value: -Infinity },
          sum: 0
        }
      );

      const avg = sum / data.length;
      const range = max.value - min.value;

      let frequency = {};
      let maxmode = 0;
      let mode;

      //mode calcultation
      for (let val of data) {
        frequency[val.value] = (frequency[val.value] || 0) + 1;
        if (frequency[val.value] > maxmode) {
          maxmode = frequency[val.value];
          mode = val.value;
        }
    }


      return { min, max, avg ,range,mode };
    }

    function getPctChange() {
      const firstVal = data[0].value;
      const lastVal = data[data.length - 1].value;

      return ((lastVal - firstVal) / firstVal) * 100;
    }

    if (data.length === 0) return;

    return {
      ...getMinMaxAvg(),
      pctChange: getPctChange()
    };
  }

  const stats = getStats();

  return (
    <div className="ChartStatisticsCard">
      <div className="title">Chart Statistics</div>
      <div className="body">
        
        <Stat
          label="Highs"
          value={stats?.max.value}
          unit={unit}
          time={stats?.max.time}
        />
        <Stat label="Average" value={stats?.avg} unit={unit} />
        <Stat
          label="Lows"
          value={stats?.min.value}
          unit={unit}
          time={stats?.min.time}
        />
        <Stat label="% Change" value={stats?.pctChange} unit="%" />
        <Stat label="Mode" value={stats?.mode} unit={unit} />
        <Stat label="Range" value={stats?.range} unit={unit} />
        
      </div>
    </div>
  );
}

function Stat({ label, value, unit, time }) {
  function getValueStr() {
    function getUnitStr() {
      return (unit !== '%' ? ' ' : '') + unit;
    }

    function getTimeStr() {
      if (time === undefined) return;

      // Example: 31/01 10:07
      const date = new Date(time);
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const hour = date.getHours().toString().padStart(2, '0');
      const minute = date.getMinutes().toString().padStart(2, '0');

      return `${day}/${month} ${hour}:${minute}`;
    }

    if (typeof value !== 'number' || Number.isNaN(value)) return '-';

    const unitStr = getUnitStr();
    const timeStr = getTimeStr();

    let retVal = `${value.toFixed(1)}${unitStr}`;
    if (timeStr) retVal += ` @ ${timeStr}`;

    return retVal;
  }

  return (
    <div className="Stat">
      <div className="label">{label}:</div>
      <div className="value">{getValueStr()}</div>
    </div>
  );
}

export default ChartStatisticsCard;
