import './styles/WeatherSoilSwitch.css';
import { useEffect ,useRef} from 'react';

function WeatherSoilSwitch({
  onDataSourceClick,
  Disable,
  currentState,
  onedevice
}) {

  function handleDataSourceClick(dataSouce) {
    onDataSourceClick(dataSouce);
    
  }
  const ref = useRef(null);


  const cb = document.querySelector('#switch');
  console.log(cb?.checked);

  if (onedevice===false){
    console.log("clicked")
  if ( currentState==='weather-station' && cb?.checked){
    document.getElementById('switch')?.click();
  }
  else if( currentState==='soil-data-logger' && !cb?.checked){
    document.getElementById('switch')?.click();
    
  }

}



  //useEffect(() => {
   // const interval = setInterval(() => {
   //   autoSwitchPage();
   // }, 30000);
   // return () => clearInterval(interval);
 // }, []);


  ///setInterval(autoSwitchPage, 10000);

  return (
    <div>
      <label class="toggle">
        <input type="checkbox" id="switch" name="switch" onClick={handleDataSourceClick} disabled={Disable}/>
        <span  ref={ref}  class="labels" data-weather="WEATHER" data-soil="SOIL"></span>
      </label>
    </div>
  );
}



export default WeatherSoilSwitch;
