import './styles/AnovaWeather.css';
import AnovaSkeleton from 'components/layouts/AnovaSkeleton';

function createDataType(id, unit, name, getDataFromHistoricData) {
  return { id, name, unit, getDataFromHistoricData };
}
const DATA_TYPES = [
  createDataType(
    'temperature',
    '°C',
    'Temperature',
    data => data.temperature.outside
  ),
  createDataType(
    'dew-point',
    '°C',
    'Dew point',
    data => data.temperature.dewPoint
  ),
  createDataType(
    'heat-index',
    '°C',
    'Heat index',
    data => data.temperature.heatIndex
  ),
  createDataType(
    'wind-chill',
    '°C',
    'Wind chill',
    data => data.temperature.windChill
  ),
  createDataType('humidity', '%', 'Humidity', data => data.humidity),
  createDataType('wind-speed', 'km/h', 'Wind speed', data => data.wind.speed),
  createDataType(
    'wind-direction',
    '°',
    'Wind direction',
    data => data.wind.direction
  ),
  createDataType('barometric-pressure', 'mm Hg', 'Barometric pressure' , data => data.barometricPressure),
  createDataType(
    'temperature-humidity-index',
    null,
    'THI',
    data => data.temperatureHumidityIndex
  ),
  createDataType('rainfall', 'mm', 'Rainfall', data => data.rainfall)
];

function AnovaWeather({
  selectedDataSource,
  historicTime,
  dataType,
  historicData,
  historicData1,
  historicData2,
  historicData3,
  historicData4,
  onHistoricTimeChange,
  onDataTypeChange,
  onSave1,
  onSave2,
  onSave3,
  onSave4
}) {


  function getTreat1Data() {
    if (historicData1 === undefined || historicData1[selectedDataSource.id] === undefined) return [];

    let treat1Data = [];

      const getValueFromData = DATA_TYPES.find(
        el => el.id === dataType
      ).getDataFromHistoricData;

      treat1Data = historicData1[selectedDataSource.id].map(data => ({
        time: data.time,
        value: getValueFromData(data)
      }));
    

    return treat1Data;
  }

  function getTreat2Data() {
    if (historicData2 === undefined || historicData2[selectedDataSource.id] === undefined) return [];

    let treat2Data = [];

      const getValueFromData = DATA_TYPES.find(
        el => el.id === dataType
      ).getDataFromHistoricData;

      treat2Data = historicData2[selectedDataSource.id].map(data => ({
        time: data.time,
        value: getValueFromData(data)
      }));
    

    return treat2Data;
  }

  function getTreat3Data() {
    if (historicData3 === undefined || historicData3[selectedDataSource.id] === undefined) return [];
    let treat3Data = [];

      const getValueFromData = DATA_TYPES.find(
        el => el.id === dataType
      ).getDataFromHistoricData;

      treat3Data = historicData3[selectedDataSource.id].map(data => ({
        time: data.time,
        value: getValueFromData(data)
      }));
    

    return treat3Data;
  }

  function getTreat4Data() {
    if (historicData4 === undefined || historicData4[selectedDataSource.id] === undefined) return [];

    let treat4Data = [];

      const getValueFromData = DATA_TYPES.find(
        el => el.id === dataType
      ).getDataFromHistoricData;

      treat4Data = historicData4[selectedDataSource.id].map(data => ({
        time: data.time,
        value: getValueFromData(data)
      }));
    

    return treat4Data;
  }


  return (
    <div className="AnovaWeather">
      <AnovaSkeleton
        historicTime={historicTime}
        dataTypes={DATA_TYPES}
        selectedDataType={dataType}
        treat1Data={getTreat1Data()}
        treat2Data={getTreat2Data()}
        treat3Data={getTreat3Data()}
        treat4Data={getTreat4Data()}
        onHistoricTimeChange={onHistoricTimeChange}
        onDataTypeChange={onDataTypeChange}
        onSave1={onSave1}
        onSave2={onSave2}
        onSave3={onSave3}
        onSave4={onSave4}
      />
    </div>
  );
}

export default AnovaWeather;
