import './styles/BaroPressCard.css';
import Card from 'components/misc/Card';
import StraightGauge from 'components/misc/StraightGauge';

function BaroPressCard({ baroPress }) {
  return (
    <div className={"BaroPressCard"+
    (baroPress <= 810 ? '' : ' abnormal')}>
      <Card title="Barometric Pressure">
        <StraightGauge
          value={
            typeof baroPress !== 'number'
              ? undefined
              : parseFloat(baroPress.toFixed(1))
          }
          min={0}
          max={820}
          unit="mm Hg"
        />
      </Card>
    </div>
  );
}

export default BaroPressCard;
