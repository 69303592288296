import './styles/RegressionInfoCard.css';
import regression from 'regression';

function RegressionInfoCard({ data1, data2, selected1, selected2, unit }) {
  function getRegression() {

    const regress1 = data1.map(d => d.value);
    const regress2 = data2.map(d => d.value);

    try {
      let i=0;
      let count = regress1.length;
      let line_data=[];
      
      while (i<count) {
        var plot = [regress1[i], regress2[i]];
        line_data.push(plot);
        i++
      }

      // console.log('line data')
      // console.log(line_data)
      
      const result = regression.linear(
        line_data
      );

      return result
      
    } catch (error) {
      console.error(error);
    }

  }

  function getDataTypes() {
    try {
    var type1=selected1;
    var dataType1= type1.charAt(0).toUpperCase() + type1.slice(1)

    var type2=selected2;
    var dataType2= type2.charAt(0).toUpperCase() + type2.slice(1)

    const types=[dataType1, dataType2]
    // console.log(types)
    return types

    } catch (error) {
      console.error(error);
    }
  }

  const regress = getRegression();
  const dataTypes= getDataTypes();


  return (
    <div className="RegressionInfoCard">
      <div className="title">Linear Regression Analysis</div>
      <div className="body">
        <div>Parameters: {dataTypes[0]} x {dataTypes[1]}</div>
        <div>Gradient: {regress?.equation[0]}</div>
        <div>Y-Intercept: {regress?.equation[1]}</div>
        <div>Equation: {regress?.string}</div>
        <div>R Square: {regress?.r2}</div>
        
      </div>
    </div>
  );
}

export default RegressionInfoCard;
