import { useState } from 'react';
import './styles/AnovaSkeleton.css';
import DataTypeSelectHeaderCard from 'components/misc/DataTypeSelectHeaderCard';
import AnovaInfoCard from 'components/misc/AnovaInfoCard';
import AnovaTimeSelect from 'components/misc/AnovaTimeSelect';
import AnovaTreatCard from 'components/misc/AnovaTreatCard';

function AnovaSkeleton({
  historicTime,
  dataTypes,
  selectedDataType,
  treat1Data,
  treat2Data,
  treat3Data,
  treat4Data,
  onHistoricTimeChange,
  onDataTypeChange,
  onSave1,
  onSave2,
  onSave3,
  onSave4,
  sensorType
}) {
  console.log(sensorType)
  if (sensorType!==undefined){
    if (sensorType==='fixed_soil'){
      
      dataTypes=dataTypes.filter(function(ele){ 
        return ele.id !== 'ultrasonic-water-level' && ele.id !== 'location_name'; 
    });

    if (selectedDataType==='ultrasonic-water-level' ){
      selectedDataType='temperature';
      onDataTypeChange(selectedDataType);
    }
    }

    else if(sensorType==='npk_portable'){
      
      dataTypes=dataTypes.filter(function(ele){ 
        return ['temperature','cation-exchange-capacity','electroconductivity',
        'salinity','nitrogen','phosphorus','potassium','ph','moisture'].includes(ele.id) ; 
    });
    
    if (selectedDataType==='ultrasonic-water-level' || selectedDataType==='diffuse-oxygen' ){
      selectedDataType='temperature';
      onDataTypeChange(selectedDataType);
    }

    
    }

    else if(sensorType==='ultrasonic'){

      
      dataTypes=dataTypes.filter(function(ele){ 
        return ele.id === 'ultrasonic-water-level'; 
    });
    selectedDataType='ultrasonic-water-level';
    onDataTypeChange(selectedDataType);
    }

    else if(sensorType==='do_portable'){

      
      dataTypes=dataTypes.filter(function(ele){ 
        return ele.id === 'diffuse-oxygen'; 
    });
    selectedDataType='diffuse-oxygen';
    onDataTypeChange(selectedDataType);
    }

  }
  
  const [isLoading, setIsLoading] = useState(false);

  async function handleSaveClick1() {

    await onSave1(historicTime.start, historicTime.duration);
    
    // setIsSaved1(false);
  }

  async function handleSaveClick2() {

    await onSave2(historicTime.start, historicTime.duration);
    
    // setIsSaved2(false);
  }

  async function handleSaveClick3() {

    await onSave3(historicTime.start, historicTime.duration);
    
    // setIsSaved3(false);
  }
 
  async function handleSaveClick4() {

    await onSave4(historicTime.start, historicTime.duration);
    
    // setIsSaved3(false);
  }

  return (
    <div className="AnovaSkeleton">
      <div className="header">
        <AnovaTimeSelect
          historicTime={historicTime}
          isDisabled={isLoading}
          onChange={onHistoricTimeChange}
        />
        <DataTypeSelectHeaderCard
          els={dataTypes}
          selected={selectedDataType}
          isDisabled={isLoading}
          onChange={onDataTypeChange}
        />
      </div>
      <div className="body">
        <AnovaTreatCard
          data={treat1Data}
          unit={dataTypes.find(el => el.id === selectedDataType).unit}
          isDisabled={isLoading}
          onSaveClick={handleSaveClick1}
        />
        <AnovaTreatCard
          data={treat2Data}
          unit={dataTypes.find(el => el.id === selectedDataType).unit}
          isDisabled={isLoading}
          onSaveClick={handleSaveClick2}
        />
        <AnovaTreatCard
          data={treat3Data}
          unit={dataTypes.find(el => el.id === selectedDataType).unit}
          isDisabled={isLoading}
          onSaveClick={handleSaveClick3}
        />
        <AnovaTreatCard
          data={treat4Data}
          unit={dataTypes.find(el => el.id === selectedDataType).unit}
          isDisabled={isLoading}
          onSaveClick={handleSaveClick4}
        /> 
      </div>         
      <div className="bottom">  

        <AnovaInfoCard
          treat1Data={treat1Data}
          treat2Data={treat2Data}
          treat3Data={treat3Data}
          treat4Data={treat4Data}
          unit={dataTypes.find(el => el.id === selectedDataType).unit}
        />  

      </div>  
        
    </div>

  );
}

export default AnovaSkeleton;
