import './styles/HomeWeather.css';
import HomeSkeleton from 'components/layouts/HomeSkeleton';
import ThiHeaderCard from 'components/misc/ThiHeaderCard';
import BatteryStatusHeaderCard from 'components/misc/BatteryStatusHeaderCard';
import LastUpdatedTimeHeaderCard from 'components/misc/LastUpdatedTimeHeaderCard';
import TemperatureCard from 'components/misc/TemperatureCard';
import WindSpeedCard from 'components/misc/WindSpeedCard';
import WindDirectionCard from 'components/misc/WindDirectionCard';
import HumidityCard from 'components/misc/HumidityCard';
import BaroPressCard from 'components/misc/BaroPressCard';
import RainfallCard from 'components/misc/RainfallCard';
import MapCard from 'components/misc/MapCard';
import Card from 'components/misc/Card';
import StraightGauge from 'components/misc/StraightGauge';



function HomeWeather({ isOutdoor, currentWeather, prevThi , deviceLocation ,sitePlantations}) {
  function getThiPctChange() {

    
    console.log(deviceLocation)

    if (currentWeather === undefined || prevThi === undefined) return;

    const currThi = currentWeather.temperatureHumidityIndex;

    return ((currThi - prevThi) / prevThi) * 100;
  }

  return (
    <div className="HomeWeather">
      <HomeSkeleton
        header={
          <>
            <ThiHeaderCard
              value={currentWeather?.thi}
              pctChange={getThiPctChange()}
            />
            <BatteryStatusHeaderCard isLow={currentWeather?.battery_status} />
            <LastUpdatedTimeHeaderCard time={currentWeather?.datetime} />
          </>
        }



        body={
          <>
       
          <MapCard
          lat={deviceLocation?.lat}
          long={deviceLocation?.long}
          deviceRadius={deviceLocation?.radius}
          plant_type={sitePlantations?.plant_type}
          tree_number={sitePlantations?.tree_number}
          animal_number={sitePlantations?.animal_number}
          hectarage={sitePlantations?.hectarage}
          site_manager={sitePlantations?.site_manager}
          staff_number={sitePlantations?.staff_number}
          device_type={deviceLocation?.sensortype}
          device_ID={currentWeather?.device_id}
            />   
          <div className='parameters'>           
            <TemperatureCard
              outside={currentWeather?.temperature}
              dewPoint={currentWeather?.dew_point}
              heatIndex={currentWeather?.heat_index}
              windChill={currentWeather?.wind_chill}
            />
            <WindSpeedCard speed={currentWeather?.wind_speed} />
            <WindDirectionCard
              directionAngle={currentWeather?.wind_direction}
            />
              <HumidityCard humidity={currentWeather?.humidity} />
              {isOutdoor ? (
                <RainfallCard
                  current={currentWeather?.rainfall_current}
                  daily={currentWeather?.rainfall_daily}
                  monthly={currentWeather?.rainfall_monthly}
                  yearly={currentWeather?.rainfall_yearly}
                />
              ) : null}
              <BaroPressCard baroPress={currentWeather?.air_pressure} />
              <SolarRadCard value={currentWeather?.solar_radiation} />
            </div>      
          </>
        }
      />
    </div>
  );


  function SolarRadCard({ value }) {
    return (
      <div className={"solar" +
      (value <= 40 ? '' : ' abnormal')}>
      <StraightGaugeCard
        title="Solar Radiation"
        isVertical
        value={value}
        min={0}
        max={1000}
        unit="W/m²"
      />
      </div>
    );
  }


  function StraightGaugeCard({ title, isVertical, value, min, max, unit }) {
    return (
      <div className="StraightGaugeCard" >
        <Card title={title}>
          <StraightGauge
            isVertical={isVertical}
            value={value}
            min={min}
            max={max}
            unit={unit}
          />
        </Card>
      </div>
    );
  }

}


export default HomeWeather;
