import { useEffect, useState } from 'react';
import './styles/MapCard.css';
import Card from 'components/misc/Card';
import mapImg from 'assets/img/map2.jpg';
import GoogleMapReact from 'google-map-react';
import { MapContainer, TileLayer, useMap,Marker ,Circle ,Tooltip } from 'react-leaflet'

import { LeafletProvider } from 'react-leaflet';
import { FullscreenControl } from "react-leaflet-fullscreen";
import "react-leaflet-fullscreen/dist/styles.css";
import 'leaflet/dist/leaflet.css'
import L from 'leaflet'
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import plant from 'assets/img/plant.png';
import cow from 'assets/img/cow.png';
import land from 'assets/img/land.png';
import nature from 'assets/img/nature.png';
import team from 'assets/img/team.png';
import manager from 'assets/img/manager.png';
import databaseapi from 'modules/databaseapi';
import MapPrint from 'modules/MapPrint';

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconAnchor: [12, 40]
});

L.Marker.prototype.options.icon = DefaultIcon;


function MapCard({lat,long,deviceRadius,plant_type,tree_number
  ,animal_number,hectarage,site_manager,staff_number,device_type,device_ID}) {

    const [deviceID, setdeviceID] = useState(device_ID);
    const [longiTude, setlongiTude] = useState(long??0);
    const [latiTude, setlatiTude] = useState(lat??0);
    const [locationName, setlocationName] = useState('');
    const [tempLong, settempLong] = useState(0);
    const [tempLat, setLat] = useState(0);



  useEffect(() => {
    settempLong(long??0);
    setLat(lat??0);
    const defaultProps = {
      center: {
        lat: lat,
        lng: long
      },
      zoom: 11
    };

    // eslint-disable-next-line
  }, [lat, long]);

function ChangeView({ center, zoom }) {
    const map = useMap();
    map.setView(center, zoom);

    return null;
  }




  async function handlePostLocation() {
    const postMessage = await databaseapi.postlocation(device_ID,longiTude,latiTude,locationName);
    settempLong(longiTude)
    setLat(latiTude)
    console.log(postMessage);
  }

  return (
    <div className="MapCard" >
      <Card title="Device Location">
      {device_type === 'npk_portable'||device_type === 'do_portable'||device_type === 'ultrasonic' ? (
          <div  className="locationform">
            <input type="text" placeholder="Location Name" id="locationname" name="locationname" className="locationname"
            onChange={e => setlocationName(e.target.value)}></input>
            <input type="number" placeholder="Longitude" id="longitude " name="longitude " className="longitude"
            onChange={e => setlongiTude(e.target.value)}></input>
            <input type="number" placeholder="Latitude" id="latitude" name="latitude" className="latitude"
            onChange={e => setlatiTude(e.target.value)}></input>
            <button type="button" className="log-in" onClick={handlePostLocation}>Submit</button>
          </div> 

      ):(<></>)}

            <MapContainer className="mapcontainer" fullscreenControl={true} attributionControl={false} zoomControl={false} center={[lat??0, long??0]} zoom={15}  style={{ height: '100%', width: '100%' }}>
            <MapPrint position="topleft" sizeModes={['Current', 'A4Portrait', 'A4Landscape']} hideControlContainer={false} title="Print" />
      <MapPrint position="topleft" sizeModes={['Current', 'A4Portrait', 'A4Landscape']} hideControlContainer={false} title="Export as PNG" exportOnly />
            <ChangeView  center={[tempLat, tempLong]} zoom={16} /> 
              <TileLayer
                url='https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
                maxZoom= {20}
                subdomains={['mt1','mt2','mt3']}
              />
               
              <Marker position={[parseFloat(tempLat), parseFloat(tempLong)]}>
              <Tooltip>{`Latitude: ${parseFloat(tempLat).toFixed(4)}, Longitude: ${parseFloat(tempLong).toFixed(4)}`}</Tooltip>
              </Marker>
              <Circle center={[tempLat, tempLong]} radius={deviceRadius??0}></Circle>
             
            </MapContainer>
           

        <div className='plantation'>
          <div className='plantationdiv'>
            <img id="plant" height="30px" src={plant} alt="plant logo"/> 
            <div>Type of plant: {plant_type??'-'}</div>
          </div>

          <div className='plantationdiv'>
            <img id="nature" height="30px" src={nature} alt="plant logo"/> 
            <div>Total Plantation Size: {tree_number??'- '} ha</div>
          </div>

          {animal_number > 0 ? (
          <div className='plantationdiv'>
            <img id="cow" height="30px" src={cow} alt="plant logo"/> 
            <div>Animal  Number: {animal_number??'-'}</div>
          </div>
          ):(<></>)}
        </div>

        <div className='plantation'>
          <div className='plantationdiv'>
            <img id="land" height="30px" src={land} alt="plant logo"/> 
            <div>Hectarage: {hectarage??'-'} ha</div>
          </div>

          <div className='plantationdiv'>
            <img id="manager" height="30px" src={manager} alt="plant logo"/> 
            <div>Site Manager: {site_manager??'-'}</div>
          </div>

          <div className='plantationdiv'>
            <img id="team" height="30px" src={team} alt="plant logo"/> 
            <div>Staff Number: {staff_number??'-'}</div>
          </div>
        </div>
      </Card>
      
    </div>
    
  );
}

export default MapCard;