import './styles/ChartSoil.css';
import ChartSkeleton from 'components/layouts/ChartSkeleton';



const DATA_TYPES = [
  {
    id: 'temperature',
    name: 'Temperature',
    unit: '°C',
    getDataFromHistoricData:data => data.temperature
  },
  {
    id: 'cation-exchange-capacity',
    name: 'CEC',
    unit: 'meq/100g',
    getDataFromHistoricData:data => data.cec
  },
  {
    id: 'diffuse-oxygen',
    name: 'Diffuse Oxygen',
    unit: 'mg/L',
    getDataFromHistoricData:data => data.dissolved_oxygen
  },
  {
    id: 'electroconductivity',
    name: 'Electroconductivity',
    unit: 'uS/cm',
    getDataFromHistoricData:data => data.ec
  },
  {
    id: 'salinity',
    name: 'Salinity',
    unit: 'ppt',
    getDataFromHistoricData:data => data.salinity
  },
  {
    id: 'water-level',
    name: 'Water Level',
    unit: 'm',
    getDataFromHistoricData:data => data.water_level
  },
  {
    id: 'ultrasonic-water-level',
    name: 'Ultrasonic Water Level',
    unit: 'm',
    getDataFromHistoricData:data => data.ultrasonic_level_gauge
  },
  {
    id: 'nitrogen',
    name: 'Nitrogen',
    unit: 'mg/L',
    getDataFromHistoricData:data => data.nitrogen
  },
  {
    id: 'phosphorus',
    name: 'Phosphorus',
    unit: 'mg/L',
    getDataFromHistoricData:data => data.phosphorus
  },
  {
    id: 'potassium',
    name: 'Potassium',
    unit: 'mg/L',
    getDataFromHistoricData:data => data.potassium
  },
  {
    id: 'ph',
    name: 'pH',
    unit: 'pH',
    getDataFromHistoricData:data => data.ph
  },
  {
    id: 'moisture',
    name: 'Moisture',
    unit: '%',
    getDataFromHistoricData:data => data.humidity
  },
  {
    id: 'location_name',
    name: 'Location Name',
    unit: '',
    getDataFromHistoricData:data => data.location_name
  }
];

function ChartSoil({
  deviceLocation,
  selectedDataSourceId,
  historicTime,
  dataType,
  historicData,
  onHistoricTimeChange,
  onDataTypeChange,
  onUpdate,
  currentDeviceId
}) {
  function getHistoricChartData() {
    //function getValueFromDataType(data) {
      // TODO
    //}

    if (historicData=== undefined) return [];
    else {
      if (historicData[selectedDataSourceId.id] === undefined ) return [];
    }

    let historicChartData = [];
    const getValueFromData = DATA_TYPES.find(
      el => el.id === dataType
    ).getDataFromHistoricData;

    const getValueFromDataLocation = DATA_TYPES.find(
      el => el.id === "location_name"
    ).getDataFromHistoricData;

    historicChartData = historicData[selectedDataSourceId.id].map(data => ({
      time: data.time,
      value: getValueFromData(data),
      location_name: getValueFromDataLocation(data)
    }));

    return historicChartData;
  }

  //const historicChartData = getHistoricChartData();

  //console.log(historicData)
  function getCsvData() {
    function getFilename() {
      function getDateStr(time) {
        // ex: 31-01-22, 12-34
        const date = new Date(time);
        const year = date.getFullYear().toString().substring(2);
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const hour = date.getHours().toString().padStart(2, '0');
        const minute = date.getMinutes().toString().padStart(2, '0');

        return `${day}-${month}-${year}, ${hour}-${minute}`;
      }

      // ex: FD - 7A, 04-28-22, 12-34, 24 hours.csv
      return [
        selectedDataSourceId.name,
        getDateStr(historicTime.start),
        `${historicTime.duration} hours`
      ].join(', ');
    }

    function getData() {
      function getDateStr(time) {
        // ex: 31-01-22 12:34
        const date = new Date(time);
        const year = date.getFullYear().toString().substring(2);
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const hour = date.getHours().toString().padStart(2, '0');
        const minute = date.getMinutes().toString().padStart(2, '0');

        return `${day}-${month}-${year} ${hour}:${minute}`;
      }

      const dataTypeNames = DATA_TYPES.map(
        el => el.name + (!el.unit ? '' : ` (${el.unit})`)
      );
      var data = [['Time', ...dataTypeNames]];
      if (deviceLocation?.sensortype === 'npk_portable'||deviceLocation?.sensortype === 'do_portable'||deviceLocation?.sensortype === 'ultrasonic'){
         data = [['Time', ...dataTypeNames,"Longitude","Latitude"]];
      }


      const selectedHistoricData = historicData[selectedDataSourceId.id]??[];
      for (let i = 0; i < selectedHistoricData.length; i++) {
        const el = selectedHistoricData[i];

        const values = DATA_TYPES.map(dataTypeEl => {
          //if (dataTypeEl.id === 'barometric-pressure') {
            //return historicData.barometricPressure[i].value;
          //}

          return dataTypeEl.getDataFromHistoricData(el);
        });
        var row = [getDateStr(el.time), ...values];
        if (deviceLocation?.sensortype === 'npk_portable'||deviceLocation?.sensortype === 'do_portable'||deviceLocation?.sensortype === 'ultrasonic'){
          row = [getDateStr(el.time), ...values,el.long,el.lat];
        }

        data.push(row);
      }

      return data;
    }

    if (historicData === undefined) return;

    return { filename: getFilename(), data: getData() };
  }

  return (
    <div className="ChartSoil">
      <ChartSkeleton
        historicTime={historicTime}
        dataTypes={DATA_TYPES}
        selectedDataType={dataType}
        historicData={historicData}
        historicChartData={getHistoricChartData()}
        csvData={getCsvData()}
        onHistoricTimeChange={onHistoricTimeChange}
        onDataTypeChange={onDataTypeChange}
        onUpdate={onUpdate}
        sensorType={selectedDataSourceId?.sensortype}
        currentDeviceId={currentDeviceId}
      />
    </div>
  );
}

export default ChartSoil;
