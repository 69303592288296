import './styles/AnovaTreatCard.css';
import save from '../../assets/img/save.png';
// import { ReactComponent as RefreshIcon } from 'assets/img/update.svg';
import { ReactComponent as RefreshIcon } from 'assets/img/update.svg';
import { ReactComponent as RefreshIconDark } from 'assets/img/update_dark.svg';

function AnovaTreatCard({ data, unit, isDisabled, onSaveClick }) {

  function getStats() {
    function getStartEnd() {
      console.log(data);
      
      const firstvalue=data[0];
      const lastvalue=data[data.length - 1];
      const length=data.length;


      const { sum } = data.reduce(
      (prev, curr) => {
        if (typeof curr.value !== 'number') return prev;
        const next = { ...prev };
        next.sum += curr.value;

        return next;
      },
      {
        sum: 0
      }
      );

      const avg = sum / data.length;

      const { dev } = data.reduce(
        (prev, curr) => {
          if (typeof curr.value !== 'number') return prev;
          const next = { ...prev };
          next.dev += Math.pow((curr.value-avg),2);
  
          return next;
        },
        {
          dev: 0
        }
        );
        const stddev=Math.sqrt(dev/data.length);


      return { firstvalue, lastvalue, length, avg, stddev };
    }

    if (data.length === 0) return;
    

    return {
      ...getStartEnd(),
    };
  }

  const stats = getStats();

    // TODO add variance, maybe other useful metrics

  return (
    <div className="AnovaTreatCard">
      <div className="title">Treatment</div>
      <div className="body">
        <TreatButton isDisabled={isDisabled} onClick={onSaveClick} />
        <Stat
          label="Start"
          value={stats?.firstvalue.value}
          unit={unit}
          time={stats?.firstvalue.time}
        />
        <Stat
          label="End"
          value={stats?.lastvalue.value}
          unit={unit}
          time={stats?.lastvalue.time}
        />
        <Stat
          label="Length"
          value={stats?.length}
          unit=''
        />
        <Stat
          label="Mean"
          value={stats?.avg}
          unit={unit}
        />
        <Stat
          label="Std Dev"
          value={stats?.stddev}
          unit=''
        />
        
      </div>
    </div>
  );
}

function Stat({ label, value, unit, time }) {
  function getValueStr() {
    function getUnitStr() {
      return (unit !== '%' ? ' ' : '') + unit;
    }

    function getTimeStr() {
      if (time === undefined) return;

      // Example: 31/01 10:07
      const date = new Date(time);
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const hour = date.getHours().toString().padStart(2, '0');
      const minute = date.getMinutes().toString().padStart(2, '0');

      return `${day}/${month} ${hour}:${minute}`;
    }

    if (typeof value !== 'number' || Number.isNaN(value)) return '-';

    const unitStr = getUnitStr();
    const timeStr = getTimeStr();

    let retVal = `${value.toFixed(1)}${unitStr}`;
    if (timeStr) retVal += ` @ ${timeStr}`;

    return retVal;
  }

  return (
    <div className="Stat">
      <div className="label">{label}:</div>
      <div className="value">{getValueStr()}</div>
    </div>
  );
}

function TreatButton({ isDisabled, onClick }) {
  return (
    <button className="UpdateButton" disabled={isDisabled} onClick={onClick} >
      {/* <img id="save-button" src={save} alt="save" width={35}/> */}
      <div className="whiteupdate">
        <RefreshIcon width={40} />
      </div>
      <div className="darkupdate">
        <RefreshIconDark width={40}/>
      </div>
      {/* <img id="save" src={save} alt="save" /> */}

    </button>
  );
}

export default AnovaTreatCard;
